import fromUnixTime from "date-fns/fromUnixTime";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import _truncate from "lodash-es/truncate";
import _startsWith from "lodash-es/startsWith";

import * as DeviceDataPb from "../protos/device_data_pb"

export const onlineSourceNames = ['inference', 'rawdata']
export const defaultSourceName = onlineSourceNames[1]
export const isSourceOnline = (sourceName: string | undefined) => onlineSourceNames.includes(sourceName || defaultSourceName)
export const shortSourceName = (name: string) => _truncate(name, {"length": 17, "omission": ""})
export const findSourceByName = (sourceList: SourceDTO[], sourceName: string | undefined) =>
  sourceList.find(src => _startsWith(src.name, sourceName || defaultSourceName))

export type SourceDTO = {
  name: string
  run_time: string
  residents: any[]
  data_time: { start: string, end: string } | null
}

const date0 = new Date(0)

export class Source {
  name: string
  runTime: Date
  residents: any[]
  dataTime: { start: Date, end: Date }

  constructor(dto: SourceDTO) {
    this.name = dto.name
    this.residents = dto.residents
    this.runTime = parseISO(dto.run_time)
    this.dataTime = {
      start: dto.data_time?.start ? parseISO(dto.data_time?.start) : date0,
      end: dto.data_time?.start ? parseISO(dto.data_time?.end) : date0,
    }
  }
}

export class DeviceData {
  deviceId: number
  pointCloud: Record<string, number>[]
  vitals: Record<string, any>[]
  recordedAt: number

  constructor(data: DeviceDataPb.DeviceData) {
    this.deviceId = data.getDeviceId()
    this.vitals = data.getVitalsList()
    this.recordedAt = data.getRecordedAt()

    this.pointCloud = data.getPointCloudList().map(p => {
      return {
        x: p.getX(),
        y: p.getY(),
        z: p.getZ(),
        v: p.getVelocity(),
        m: p.getMagnitude(),
      }
    })
    this.vitals = data.getVitalsList().map(v=> ({
      range: v.getRange(),
      azimuth: v.getAzimuth(),
      altitude: v.getAltitude(),
      hr: v.getHr(),
      rr: v.getRr(),
      hrfft: v.getHrfft(),
      hrpp: v.getHrpp(),
      hrcm: v.getHrcm(),
      hrcorr: v.getHrcorr(),
      rrfft: v.getRrfft(),
      rrpp: v.getRrpp(),
      rrcm: v.getRrcm(),
      rrcorr: v.getRrcorr(),
      rwf: v.getRwfList(),
      hwf: v.getHwfList(),
    }))
  }
}

export enum DeviceConnectionStatus {
    Active = 'active',
    Disconnected = 'disconnected',
    Inactive = 'inactive',
}

export function getDeviceConnectionStatus(ts: number): DeviceConnectionStatus {
    const now = Date.now() / 1000
    if (now - ts < 5 * 60) {
        return DeviceConnectionStatus.Active
    } else if (now - ts < 2 * 24 * 60 * 60) {
        return DeviceConnectionStatus.Disconnected

    }
    return DeviceConnectionStatus.Inactive

}
export function formatUnixTsToReadableText(ts: number): string {
    return formatDistanceToNow(fromUnixTime(ts), {addSuffix: true})
}
