import * as B from "babylonjs";
import {ItemType} from "../../core/entities/Item";
import {ItemServiceImpl} from "../../core/usecases/ItemService";
import {ItemRepositoryImpl} from "../../core/infrastructure/ItemRepositoryImpl";
import CloudRenderAble from "./CloudRenderAble";

const itemService = new ItemServiceImpl(new ItemRepositoryImpl())

export default class Cloud implements CloudRenderAble {
  cloudMeshes: B.InstancedMesh[] = []
  masterMesh: B.Mesh | null = null

  constructor(count: number, diameter: number) {
    this.buildMeshes(count, diameter)
  }

  public getMesh(index: number): B.InstancedMesh {
    return this.cloudMeshes[index]
  }

  public formatLabel(point: any): string {
    const {x, y, z} = point
    return `(${Cloud.f(x)},${Cloud.f(y)},${Cloud.f(z)})`
  }

  protected static f(n: number, digit = 2) {
    return n?.toFixed(digit) || '??'
  }

  protected buildMeshes(count: number, diameter: number) {
    itemService.AddItem("person-point",
      ItemType.Person,
      [diameter],
      [0, 0, 0],
      [0, 0, 0],
      {visibility: 1}
    ).then(item => {
      this.masterMesh = item.shape as B.Mesh
      this.masterMesh.registerInstancedBuffer("color", 3)
      this.masterMesh.instancedBuffers.color = B.Color3.Blue()
      this.masterMesh.isVisible = false
      for (let i = 0; i < count; i++) {
        const mi = this.masterMesh.createInstance("i" + i)
        mi.isVisible = false
        this.cloudMeshes.push(mi)
      }
      return this.cloudMeshes
    })
  }

  public dispose() {
    for (const p of this.cloudMeshes) p.dispose()
    this.cloudMeshes = []
    this.masterMesh?.dispose()
  }

  public updateAll(points: any[]) {
    for (let i = 0; i < this.cloudMeshes.length; i++) {
      this.updatePoint(i, i < points.length ? points[i] : null)
    }
  }

  public setVisibility(visibility: number) {
    if (this.masterMesh) this.masterMesh.visibility = visibility
  }

  private resize(i: number, size: number) {
    this.cloudMeshes[i].scaling = new B.Vector3(size, size, size)
  }

  private recolor(i: number, color: number) {
    this.cloudMeshes[i].instancedBuffers.color = color > 0 ? B.Color3.Red() : B.Color3.Blue()
  }

  protected relocate(i: number, [x, y, z]: number[]) {
    this.cloudMeshes[i].position = new B.Vector3(x, y, z)
  }

  protected setMeshVisible(i: number, isVisible: boolean) {
    this.cloudMeshes[i].isVisible = isVisible
  }

  protected updatePoint(i: number, p: any) {
    if (i >= this.cloudMeshes.length) return
    this.setMeshVisible(i, p !== null)
    if (!p) return
    this.recolor(i, p.v)
    this.relocate(i, [p.x, p.z, p.y])
  }
}
