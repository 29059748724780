import {useEffect, useState} from 'react'

export function useFpsTick(): [number] {
  const [fpsTick, setFpsTick] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => setFpsTick(Date.now()), 200)
    return () => clearInterval(interval)
  }, [])

  return [fpsTick];
}