import React from 'react'
import {Chart, TimeScale, Tooltip} from 'chart.js'
import 'chartjs-adapter-date-fns';
import parseISO from 'date-fns/parseISO'
import addHours from 'date-fns/addHours'
import addDays from 'date-fns/addDays'

Chart.register(TimeScale, Tooltip)

interface IPredictionChart {
  algorithm: string
  residentId: number
  endDate: number
  period: string
  isAlternateSource?: boolean,
  selectionEndDate?: number,
  updateSelectionEnd?: (endDate: number) => void
}

const PredictionChart: React.FC<IPredictionChart> = (
  {
    algorithm,
    residentId,
    endDate,
    period,
    isAlternateSource = false,
    selectionEndDate,
    updateSelectionEnd
  }) => {
  const [readings, setReadings] = React.useState<any[] | null>(null);
  const [chart, setChart] = React.useState<Chart | null>(null);
  const [drag, setDrag] = React.useState(false)
  const [animationFrame, setAnimationFrame] = React.useState(0)

  const chartBoard = React.useRef<HTMLCanvasElement | null>(null);
  const overlay = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    if (!endDate || !period) return

    const getUrl = window.location;
    const baseUrl = getUrl.protocol + '//' + getUrl.hostname + ':8088'
    const url = new URL('/readings', baseUrl)
    const params = {endDate, period, isAlternateSource, residentId} as any
    url.search = new URLSearchParams(params).toString();
    fetch(url as any)
      .then(res => res.json())
      .then(data => setReadings(data))
      .catch(err => console.error('caught it!', err))
    return () => {
      setReadings(null)
    }
  }, [residentId, endDate, period, isAlternateSource])

  const handleResize = (chart: Chart, size: { width: number; height: number }) => {
    if (!overlay.current) return
    overlay.current.width = size.width
    overlay.current.height = size.height
  }
  const handleOnComplete = () => setAnimationFrame(f => (f + 1) % 10)

  React.useEffect(() => {
    const chartCanvas = chartBoard.current as HTMLCanvasElement
    const unit = ['month', 'week'].includes(period) ? 'day' : ['day'].includes(period) ? 'hour' : 'minute'

    const c = createChart(chartCanvas, unit, handleResize, handleOnComplete)
    setChart(c)
    return () => {
      c.destroy()
      setChart(null)
    }
  }, [period])

  React.useEffect(() => {
    if (!chart) return

    const endDateObj = new Date(endDate)
    const startDateObj = addDays(endDateObj, period === 'day' ? 1 : 7);
    (chart.options.scales as any).x = {
      ...chart.options.scales?.x,
      min: startDateObj.toString(),
      max: endDateObj.toString()
    }
    chart.canvas && chart.update()
  }, [chart, endDate, period])

  React.useEffect(() => {
    if (!chart) return
    chart.data.datasets[0].label = `(${period} view @` + (isAlternateSource ? 'test' : 'control') + ')'
    chart.canvas && chart.update()
  }, [chart, algorithm, period, isAlternateSource])

  React.useEffect(() => {
    if (!chart || !readings) return
    chart.data.datasets[0].data = readings.map(r => ({value: r[algorithm], Time: parseISO(r.Time)})) as any
    chart.canvas && chart.update()
  }, [chart, readings, algorithm])

  const findReadingIndexAt = React.useCallback((startIndex: number, targetTime: Date, sign: number)=>{
    if (!chart) return -1
    const data = chart.data.datasets[0].data as unknown as {Time: Date}[]
    if (!data.length) return -1

    let currentTime = data[startIndex].Time
    let i = startIndex
    for (; (sign > 0 && currentTime < targetTime) || (sign < 0 && currentTime > targetTime); i += sign) {
      if (i < 0) return 0
      if (i >= data.length) return data.length - 1
      currentTime = data[i].Time
    }
    return data.length > i ? i : data.length - 1
  },[chart])

  const findHoursOffsetReadingIndex = React.useCallback((currentIndex: number, hours: number) => {
    if (!chart) return -1
    const data = chart.data.datasets[0].data as unknown as {Time: Date}[]
    if (!data.length) return -1

    let currentTime = data[currentIndex].Time
    return findReadingIndexAt(currentIndex, addHours(currentTime, hours), Math.sign(hours))
  },[chart, findReadingIndexAt])

  const drawSelection = React.useCallback((endIndex: number) => {
    if (!chart || !readings || !updateSelectionEnd) return
    const selectionContext = overlay.current?.getContext('2d')
    const dataMeta = chart.getDatasetMeta(0).data as { x: number, y: number }[]
    if (!selectionContext || !dataMeta.length) return

    const startIndex = findHoursOffsetReadingIndex(endIndex, -24)
    const startX = dataMeta[startIndex].x
    const endX = dataMeta[endIndex].x

    const canvas = chartBoard.current as HTMLCanvasElement
    selectionContext.globalAlpha = 0.5;
    selectionContext.clearRect(0, 0, canvas.width, canvas.height);
    selectionContext.fillRect(startX, chart.chartArea.top, endX  - startX, chart.chartArea.bottom - chart.chartArea.top)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chart, readings, findHoursOffsetReadingIndex])

  React.useEffect(() => {
    if (selectionEndDate === undefined) return
    const endIndex = findReadingIndexAt(0, new Date(selectionEndDate), 1)
    drawSelection(endIndex)
  }, [selectionEndDate, findReadingIndexAt, drawSelection, animationFrame])

  const handlePointerEvent = (evt: any) => {
    if (!chart || !drag) return
    const points = chart.getElementsAtEventForMode(evt, 'index', {intersect: false}, false)
    if (!points[0]) return

    const endIndex = findHoursOffsetReadingIndex(points[0].index, 12)
    drawSelection(endIndex)
  }
  const handlePointerDown = () => setDrag(true)
  const handlePointerUp = (evt: any) => {
    setDrag(false)

    if (!chart || !updateSelectionEnd) return
    const points = chart.getElementsAtEventForMode(evt, 'index', {intersect: false}, false)
    if (!points[0]) return

    const endIndex = findHoursOffsetReadingIndex(points[0].index, 12)
    const endDate = (chart.data.datasets[0].data[endIndex] as any).Time as Date
    updateSelectionEnd(endDate.getTime())
  }

  return <>
    <canvas ref={overlay} width="400" height="100" style={{position: 'absolute', pointerEvents: 'none'}}/>
    <canvas ref={chartBoard} width="400" height="100"
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerEvent}
            onPointerUp={handlePointerUp}
    />
  </>
}
export default PredictionChart

const createChart = (chartCanvas: HTMLCanvasElement, unit: string, onResize: any, onComplete: any) => {
  return new Chart(chartCanvas, {
    type: 'line',
    data: {datasets: [{label: '', data: []}]},
    options: {
      onResize,
      animation: {onComplete},
      parsing: {xAxisKey: 'Time', yAxisKey: 'value'},
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {unit: unit, displayFormats: {minute: 'HH:mm', hour: 'HH'}} as any,
          ticks: {source: "auto", autoSkip: true} as any,
        } as any,
        y: {
          ticks: {
            stepSize: 1,
            callback: ((isTrue: number) => isTrue ? 'y' : 'n') as any
          },
          min: 0,
          max: 1,
        }
      }
    },
  })
}
