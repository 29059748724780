import * as B from "babylonjs";
import CloudRenderAble from "./CloudRenderAble";

const VEL_SCALE = 0.5

export default class Arrows implements CloudRenderAble {
  masterShaft: B.LinesMesh
  shaftCloud: B.InstancedMesh[] = []
  shiftScale: number = 1

  constructor(count: number, diameter: number) {
    this.shiftScale = diameter/2
    const bodyPoints = [
      new B.Vector3(0, 0, 0),
      new B.Vector3(1, 1, 1)
    ]
    this.masterShaft = B.MeshBuilder.CreateLines("lines", {points: bodyPoints});
    this.masterShaft.isVisible = false

    for (let i = 0; i < count; i++) {
      const ab = this.masterShaft.createInstance("arrowBody" + i)
      ab.isVisible = false
      this.shaftCloud.push(ab)
    }
  }

  public getMesh(index: number): B.InstancedMesh {
    return this.shaftCloud[index]
  }

  public formatLabel(point: any): string {
    return ''
  }

  public dispose() {
    for (const p of this.shaftCloud) p.dispose()
    this.shaftCloud = []
    this.masterShaft?.dispose()
  }

  public updateAll(points: any[]) {
    for (let i = 0; i < this.shaftCloud.length; i++) {
      this.updateArrow(i, i < points.length ? points[i] : null)
    }
  }

  public setVisibility(visibility: number) {
    this.masterShaft.alpha = visibility
  }

  private setArrowVisible(i: number, isVisible: boolean) {
    this.shaftCloud[i].isVisible = isVisible
  }

  private relocate(i: number, [x, y, z, v]: number[]) {
    const shaftLen = VEL_SCALE * Math.sqrt(Math.abs(v)) * Math.sign(v)
    const dToO = Math.sqrt(x * x + y * y + z * z)
    const sx = shaftLen * x / dToO
    const sy = shaftLen * y / dToO
    const sz = shaftLen * z / dToO
    const body = this.shaftCloud[i]
    body.scaling.x = sx
    body.scaling.y = sy
    body.scaling.z = sz
    const shift = this.shiftScale * Math.sign(v)
    body.position.x = x + shift * x / dToO
    body.position.y = y + shift * y / dToO
    body.position.z = z + shift * z / dToO
  }

  private updateArrow(i: number, p: any) {
    if (i >= this.shaftCloud.length) return
    this.setArrowVisible(i, p !== null)
    if (!p) return
    this.relocate(i, [p.x, p.z, p.y, p.v])
  }
}