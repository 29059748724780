import * as B from "babylonjs";
import {ItemType} from "../../core/entities/Item";
import {ItemServiceImpl} from "../../core/usecases/ItemService";
import {ItemRepositoryImpl} from "../../core/infrastructure/ItemRepositoryImpl";
import Cloud from "./Cloud";

const itemService = new ItemServiceImpl(new ItemRepositoryImpl())

export default class Centroids extends Cloud {
  cloudMeshes: B.InstancedMesh[] = []
  masterMesh: B.Mesh | null = null

  protected buildMeshes(count: number, diameter: number) {
    itemService.AddItem("person-centroids",
      ItemType.Person,
      [diameter],
      [0, 0, 0],
      [0, 0, 0],
      {visibility: 1}
    ).then(item => {
      this.masterMesh = item.shape as B.Mesh
      this.masterMesh.isVisible = false
      for (let i = 0; i < count; i++) {
        const mi = this.masterMesh.createInstance("i" + i)
        mi.isVisible = false
        this.cloudMeshes.push(mi)
      }
      return this.cloudMeshes
    })
  }

  protected updatePoint(i: number, p: any) {
    if (i >= this.cloudMeshes.length) return
    this.setMeshVisible(i, p !== null)
    if (!p) return
    this.relocate(i, [p.x, p.z, p.y])
  }
}
