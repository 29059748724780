import _get from "lodash-es/get"
import React, {useState} from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from "@mui/material/CardActions"
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Autocomplete from '@mui/material/Autocomplete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button"
import {styled} from '@mui/material/styles'
import {DataGrid, GridColDef, GridRowsProp, GridCellParams} from '@mui/x-data-grid'
import "./InfoCard.css"
import {DeviceMetaDTO} from "../core/infrastructure/dtos/DeviceMetaDTO";
import PredictionView from "./predictionView";
import {formatUnixTsToReadableText, getDeviceConnectionStatus, shortSourceName, SourceDTO} from "./device-data.service";
import {RoomType} from "./elements/RoomType";
import WaveformChart from "./components/WaveformChart";
import {heartColor, breathColor, fmtVital} from "./utils";

interface IInfoCard {
  theme: string,
  minimized: boolean,
  username: string
  timers: Record<string, any>
  dataTime: number
  fps: number
  rawVitals: any
  generatedVitals: Record<string, number>
  points: number
  sourceName: string
  sources: SourceDTO[]
  onSourceChange: any
  deviceId: string | undefined
  devices: Record<number, DeviceMetaDTO[]>
  deviceConnections: Record<number, number>
  onDeviceChange: any
  resident: any,
}

const ExpandMore = styled((props: any) => {
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const infoStyle: any = {sx: {fontFamily: 'monospace', ml: 1}, color: 'default', size: 'small'}
const deviceStyle: any = {...infoStyle, color: 'primary'}
const statsStyle: any = {...infoStyle, color: 'success', variant: 'outlined'}
const dialogStyle: any = {sx: {position: 'absolute', right: 0, top: 0, opacity: .8, maxWidth: "700px"}, className: 'none-user-select'}

const card = (info: IInfoCard,
              openDeviceList: boolean,
              setOpenDeviceList: any,
              openSourceList: boolean,
              setOpenSourceList: any,
              interactions: any,
) => {
  const {username, deviceId, fps, generatedVitals, points, sourceName, sources, onSourceChange,
    devices, deviceConnections, onDeviceChange, resident} = info

  const handleOpenDeviceList = () => setOpenDeviceList(true)
  const handleOpenSourceList = () => setOpenSourceList(true)
  const handleDeviceListClose = () => setOpenDeviceList(false)
  const handleSourceListClose = () => setOpenSourceList(false)
  const deviceIds = Object.keys(devices).map(id => +id).sort((a, b) => a - b)
  const deviceRows: GridRowsProp = deviceIds.map(did => ({
    id: did,
    status: getDeviceConnectionStatus(deviceConnections[did]),
    residentId: _get(devices[did], '0.id'),
    resident: _get(devices[did], '0.name'),
    facility: _get(devices[did], '0.facility.name') || _get(devices[did], '0.facility_name'),
    room: _get(devices[did], '0.room.number') || _get(devices[did], '0.room_number'),
    roomType: RoomType[(_get(devices[did], '0.room.type') || _get(devices[did], '0.room_type')) as RoomType],
  }))
  const deviceColDefs: GridColDef[] = [
    {field: 'id', headerName: 'Device', width: 80, renderCell: (params: GridCellParams<number>) => `${params.value}`.padStart(6, '0')},
    {
      field: 'status', headerName: 'Status', width: 80,
      renderCell: (params: GridCellParams<string>) => {
        const timestamp = deviceConnections[params.row.id]
        const timeText = timestamp ? 'Last activity: ' + formatUnixTsToReadableText(timestamp) : ''
        return <Tooltip title={timeText}><span>{params.value}</span></Tooltip>
      },
      cellClassName: (params: GridCellParams<string>) => `device-connection-status--${params.value}`
},
    ...(info.theme !== 'presentation' ? [{field: 'resident', headerName: 'Resident', width: 90}] : []),
    {field: 'residentId', headerName: 'ResID', width: 75},
    ...(info.theme !== 'presentation' ? [
      {field: 'facility', headerName: 'Facility', width: 200},
      {field: 'room', headerName: 'Room', width: 90}
    ] : []),
    {field: 'roomType', headerName: 'Type', width: 80},
  ]
  const sourceNames = sources.map(src => shortSourceName(src.name) )
  const srcRows: GridRowsProp = sources.map(src => ({
    id: shortSourceName(src.name),
    start: src.data_time?.start || "--",
    end: src.data_time?.end || "--",
    devices: src.residents?.map((r: any) => r["device_id"]).join(",") || "--",
  }))
  const srcColDefs: GridColDef[] = [
    {field: 'id', headerName: 'Data Source', width: 150},
    {field: 'start', headerName: 'Start', width: 170},
    {field: 'end', headerName: 'End', width: 170},
    {field: 'devices', headerName: 'Devices', width: 120},
  ]
  const handleExpandClick = () => interactions.setExpanded(!interactions.expanded)
  const handleShowRwf = () => interactions.setShowRwf(!interactions.showRwf)
  const handleShowHwf = () => interactions.setShowHwf(!interactions.showHwf)

  return (
    <CardContent sx={{py: 0}}>
      <pre style={{margin: '2px'}}>
        {info.minimized ? "" : <Chip label={`User: ${username}`} {...infoStyle}/>}
        <Chip label={`Points ${('' + points).padStart(3, ' ')}`} {...statsStyle}/>
        <Chip label={`${fmtVital('RR', generatedVitals['rr'])}`} {...statsStyle} variant={interactions.showRwf ? '' : 'outlined'} onClick={handleShowRwf}/>
        <Chip label={`${fmtVital('HR', generatedVitals['hr'])}`} {...statsStyle} variant={interactions.showHwf ? '' : 'outlined'} onClick={handleShowHwf}/>
        {info.minimized ? "" : <Chip label={`FPS ${fps.toFixed(1).padStart(4, ' ')}`} {...infoStyle}/>}
        {info.minimized ? "" :
          <Chip label={`${resident?.timezone || resident?.facility?.timezone || '--'}`} {...infoStyle}/>}
        <Chip label={`Device ID ${deviceId || '??'}`} onClick={handleOpenDeviceList} {...deviceStyle} variant={info.minimized?"outlined":""}/>
        <Chip label={shortSourceName(sourceName)} onClick={handleOpenSourceList} {...deviceStyle} variant={info.minimized?"outlined":""}/>
        {info.minimized ? "" : <ExpandMore expand={interactions.expanded} size='small'
                      onClick={handleExpandClick}>&#8632;</ExpandMore>}
      </pre>
      <Dialog open={openDeviceList} onClose={handleDeviceListClose} hideBackdrop={true} PaperProps={dialogStyle}>
        <DialogContent sx={{p: 1}}>
          <Autocomplete
            size='small'
            value={deviceId !== undefined && deviceIds.includes(+deviceId) ? +deviceId : null}
            onChange={onDeviceChange}
            options={deviceIds}
            sx={{my: 1}}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
              <TextField {...params} label="Device ID" placeholder="Choose"/>
            )}
          />
          <div style={{height: 400, width: info.theme !== 'presentation' ? 700 : 350}}>
            <DataGrid
              density='compact'
              disableColumnMenu={true}
              hideFooterSelectedRowCount={true}
              rows={deviceRows}
              columns={deviceColDefs}
              pageSize={100}
              rowsPerPageOptions={[100]}
              onSelectionModelChange={(newSelectionModel) => {
                if (newSelectionModel.length > 0) onDeviceChange(null, +newSelectionModel[0])
              }}
              selectionModel={[deviceId !== undefined ? +deviceId : 0]}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openSourceList} onClose={handleSourceListClose} hideBackdrop={true} PaperProps={dialogStyle}>
        <DialogContent sx={{p: 1}}>
          <Autocomplete
            size='small'
            value={shortSourceName(sourceName)}
            onChange={onSourceChange}
            options={sourceNames}
            sx={{my: 1}}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
              <TextField {...params} label="Source Name" placeholder="Choose"/>
            )}
          />
          <div style={{height: 400, width: 600}}>
            <DataGrid
              density='compact'
              disableColumnMenu={true}
              hideFooterSelectedRowCount={true}
              rows={srcRows}
              columns={srcColDefs}
              pageSize={100}
              rowsPerPageOptions={[100]}
              onSelectionModelChange={(newSelectionModel) => {
                if (newSelectionModel.length > 0) onSourceChange(null, newSelectionModel[0])
              }}
              selectionModel={[deviceId !== undefined ? +deviceId : 0]}
            />
          </div>
        </DialogContent>
      </Dialog>
    </CardContent>
  )
}

const InfoCard: React.FC<IInfoCard> = (info) => {
  const [openDeviceList, setOpenDeviceList] = React.useState(false)
  const [openSourceList, setOpenSourceList] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [showPredictionsView, setShowPredictionsView] = useState<boolean>(false)
  const [delay, setDelay] = useState<Record<string, any>>({})
  const [showRwf, setShowRwf] = useState(false)
  const [showHwf, setShowHwf] = useState(false)

  const dimensions = [
    {name: 'Room', ...info.resident?.room_dimension},
    {name: 'Bed', ...info.resident?.bed_dimension}
  ]
  const infoFields = Object.keys(info.resident?.room_dimension || {})

  React.useEffect(() => {
    const now = Date.now() / 1000
    const kinesisArrival = _get(info.timers, 'benchmarks.arrival_time')
    const pipelineStart = _get(info.timers, 'benchmarks.parse_raw.start')
    const lastAlgorithmEnd = _get(info.timers, 'benchmarks.VitalTrackingFn.end')
    const outOfPipeline = _get(info.timers, 'benchmarks.VisualizerDataSinkFn')
    const relayServerTimers = _get(info.timers, 'relayServer') as number[]
    const intoServer = _get(relayServerTimers, 0)
    const outOfServer = _get(relayServerTimers, (relayServerTimers?.length || 1) - 1)
    setDelay({
      sinceCreation: (now - info.dataTime).toFixed(1),
      sinceKinesis: (now - kinesisArrival).toFixed(1),
      sincePipelineIngest: (now - pipelineStart).toFixed(1),
      sinceLastAlgorithm: (now - lastAlgorithmEnd).toFixed(1),
      sinceOutOfPipeline: (now - outOfPipeline).toFixed(1),
      sinceInServer: (now - intoServer).toFixed(1),
      sinceOutOfServer: (now - outOfServer).toFixed(1),
    })
  }, [info.dataTime, info.timers])

  const interactions = {
    expanded, setExpanded, showRwf, setShowRwf, showHwf, setShowHwf
  }

  const shortenNum = (num: number, n: number) => {
    return parseFloat(num.toFixed(n))
  }

  return (
    <Box sx={{zIndex: (theme) => theme.zIndex.drawer + 2}} className={'info-card none-user-select'}>
      <Card variant="outlined" sx={{
        background: info.theme === 'presentation' ? 'transparent' : 'rgba(255,255,255,.7)',
        ...info.minimized ? {border: 'none', maxWidth: 500} : {}
      }}>
        {card(info, openDeviceList, setOpenDeviceList, openSourceList, setOpenSourceList, interactions)}
        {showRwf && <CardContent sx={{backgroundColor: breathColor+'11'}}>
          <WaveformChart title={'Respiratory Waveform'} magnitudes={info.rawVitals['rwf']} deviceId={info.deviceId} color={breathColor}/>
        </CardContent>}
        {showHwf && <CardContent sx={{backgroundColor: heartColor+'11'}}>
            <WaveformChart title={'HeartRate Waveform'} magnitudes={info.rawVitals['hwf']} deviceId={info.deviceId} color={heartColor}/>
        </CardContent>}
        {!info.minimized && expanded && <CardContent sx={{py: 0}}>
          <pre style={{margin: '1px 0'}}>
            <Chip label={`Resident ${info.resident?.id ? 'Id: ' + info.resident?.id : 'not set'}`} {...infoStyle}/>
            {<Chip label={`Since capture: ${delay['sinceCreation']}s`} {...infoStyle}/>}
          </pre>
            <pre style={{margin: '1px 0'}}>
            {<Chip label={`Since arrival @kinesis: ${delay['sinceKinesis']}s`} {...infoStyle}/>}
              {<Chip label={`Since arrival @pipeline: ${delay['sincePipelineIngest']}s`} {...infoStyle}/>}
              {<Chip label={`Since last algorithm: ${delay['sinceLastAlgorithm']}s`} {...infoStyle}/>}
          </pre>
          <pre style={{margin: '1px 0'}}>
            {<Chip label={`Since out of pipeline: ${delay['sinceOutOfPipeline']}s`} {...infoStyle}/>}
            {<Chip label={`Since visual server ingest: ${delay['sinceInServer']}s`} {...infoStyle}/>}
            {<Chip label={`Network delay: ${delay['sinceOutOfServer']}s`} {...infoStyle}/>}
          </pre>
        </CardContent>}
        {!info.minimized && expanded && <TableContainer component={Paper}>
            <Table size="small" aria-label="objects table">
                <TableHead>
                    <TableRow>
                        <TableCell>Dimension</TableCell>
                      {infoFields.map(f => <TableCell align="right" key={f}>{f}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                  {dimensions.map((row) => (
                    <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      {infoFields.map(f => <TableCell align="right" key={f}>{shortenNum(row[f],2)}</TableCell>)}
                    </TableRow>
                  ))}
                </TableBody>
            </Table>
        </TableContainer>}
        <CardActions sx={{py: 0}}>{!info.minimized && expanded && <>
            <Button size="small" color="primary" onClick={() => setShowPredictionsView(true)}>Past Predictions</Button>
          {info.resident && <PredictionView
              residentId={info.resident.id}
              open={showPredictionsView}
              onClose={() => setShowPredictionsView(false)}/>
          }
        </>}</CardActions>
      </Card>
    </Box>
  )
}
export default InfoCard