import React from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import 'chartjs-adapter-date-fns';
import PredictionChart from "./PredictionChart";

interface IPredictionView {
  residentId: number
  open: boolean
  onClose: any
}

const algorithms = ['InRoom', 'InBed', 'Asleep']
const PredictionView: React.FC<IPredictionView> = ({residentId, open, onClose}) => {
  const [endDate] = React.useState<number>(Date.now());
  const [selectionEndDate, setSelectionEndDate] = React.useState<number>(endDate);
  const [algorithm, setAlgorithm] = React.useState(algorithms[0]);

  const handleClose = () => onClose(false)

  const updateSelectionEnd = (newEndDate: number) => setSelectionEndDate(newEndDate)
  const handleAlgorithmChange = (e: SelectChangeEvent<string>) => setAlgorithm(e.target.value)

  const previewStyle = {algorithm, endDate, period: 'week', residentId, selectionEndDate, updateSelectionEnd}
  const detailedStyle = {algorithm, endDate: selectionEndDate, period: 'day', residentId}

  return (<Dialog open={open} onClose={handleClose} hideBackdrop={true} PaperProps={{sx: {minWidth: "90%"}}}>
    <DialogContent>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Typography variant="h5" gutterBottom component="div">Predictions</Typography>
        </Grid>
        <Grid item>
          <Select value={algorithm} label="Algorithm" onChange={handleAlgorithmChange} size="small">
            {algorithms.map(a => <MenuItem value={a} key={a}>{a}</MenuItem>)}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}><PredictionChart {...previewStyle} /></Grid>
        <Grid item xs={12}><PredictionChart {...detailedStyle} /></Grid>
      </Grid>
    </DialogContent>
  </Dialog>)
}
export default PredictionView