import {Item, ItemType} from "../entities/Item";
import {ItemRepository} from "../entities/ItemRepository";
import {Vec} from "../entities/Types";

export interface ItemService {
  BuildItems(): Promise<Item[]>;
}

export class ItemServiceImpl implements ItemService {
  itemRepo: ItemRepository;

  constructor(ir: ItemRepository) {
    this.itemRepo = ir;
  }

  async BuildItems(): Promise<Item[]> {
    return this.itemRepo.BuildItems();
  }

  async AddItem(name: string, type: ItemType, shape: number[], pos: Vec, rot: Vec, meta: any): Promise<Item> {
    return this.itemRepo.AddItem(name, type, shape, pos, rot, meta)
  }
}