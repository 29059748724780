import {DeviceBehaviors} from "../usecases/DeviceBehaviors";
import {Device} from "../entities/Device";
import * as B from "babylonjs"
import {Vec} from "../entities/Types";

export class DeviceBehaviorsImpl implements DeviceBehaviors {
  GetNextRay(d: Device): Vec {
    const {hRange, vRange, radarDirection: rd} = d.meta
    const hRad = B.Angle.FromDegrees(hRange).radians()
    const vRad = B.Angle.FromDegrees(vRange).radians()

    const rx = getUniformRandomRange(-vRad, vRad)
    const ry = getUniformRandomRange(-hRad, hRad)
    const localRay = (new B.Vector3(Math.tan(ry), Math.tan(rx), 1)).normalize()

    const forwardVec3 = B.Vector3.Forward()
    const radarVec3 = B.Vector3.FromArray(rd).normalize()
    const radarXZVec3 = new B.Vector3(rd[0], 0, rd[2]).normalize()
    const yaw = Math.acos(B.Vector3.Dot(forwardVec3, radarXZVec3))
    const pitch = Math.acos(B.Vector3.Dot(radarXZVec3, radarVec3))
    const rotMat = B.Matrix.RotationYawPitchRoll(yaw, pitch, 0)

    const ray = B.Vector3.TransformCoordinates(localRay, rotMat)

    return [ray.x, ray.y, ray.z]
  }
}

function getUniformRandomRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}
