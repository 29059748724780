import {useEffect, useState} from 'react';
import {DeviceMetaDTO} from "../core/infrastructure/dtos/DeviceMetaDTO";
import {
  ItemDTO,
  ItemRepositoryImpl,
  removeResidentItemPrefix,
  updateResident
} from "../core/infrastructure/ItemRepositoryImpl";
import {Item, ItemType} from "../core/entities/Item";
import {ItemServiceImpl} from "../core/usecases/ItemService";
import {DimensionDTO} from "../core/infrastructure/dtos/DimensionDTO";

const UNBOUND_DIMENSION = 10
const NOISE_REGION_PREFIX= 'noiseRegion'
const itemService = new ItemServiceImpl(new ItemRepositoryImpl())

export function useRoomItems(resident: DeviceMetaDTO) {
  const [residentHash, setResidentHash] = useState<string>("")
  const [items, setItems] = useState<Item[]>([])

  useEffect(() => {
    const roomDim = resident.room_dimension
    const bedDim = resident.bed_dimension
    setResidentHash(JSON.stringify({roomDim, bedDim}))
  }, [resident])

  useEffect(() => {
    const roomDim = resident.room_dimension
    const bedDim = resident.bed_dimension
    const noiseRegions = resident.noise_regions

    const roomW = Math.max(Math.abs(roomDim.x_max - roomDim.x_min), 0.1)
    const roomDepth = Math.max(Math.abs(roomDim.y_max), 0.1)
    const roomH = Math.max(Math.abs(roomDim.z_max - roomDim.z_min), 0.1)
    const wShift = roomW / 2 + roomDim.x_min
    const hShift = roomH / 2 + roomDim.z_min

    const bedW = Math.abs(bedDim.x_max - bedDim.x_min)
    const bedDepth = Math.abs(bedDim.y_max - bedDim.y_min)
    const bedH = Math.abs(roomDim.z_min / 2)
    const bedRegionH = Math.abs(bedDim.z_max - bedDim.z_min)
    const bedWShift = (bedDim.x_max + bedDim.x_min) / 2
    const bedDepthShift = (bedDim.y_max + bedDim.y_min) / 2
    const bedHShift = roomDim.z_min + bedH / 2
    const bedRegionHShift = (bedDim.z_max + bedDim.z_min) / 2

    const residentItems: any = [
      {name: "wall1", shape: [roomW, roomH], pos: [wShift, hShift, 0]},
      {name: "wall2", shape: [roomDepth, roomH], pos: [wShift - roomW / 2, hShift, roomDepth / 2]},
      {name: "wall3", shape: [roomW, roomH], pos: [wShift, hShift, roomDepth]},
      {name: "wall4", shape: [roomDepth, roomH], pos: [wShift + roomW / 2, hShift, roomDepth / 2]},
      {name: "ground", shape: [roomW, roomDepth, 3], pos: [wShift, roomDim.z_min, roomDepth / 2]},
      {name: "groundBody", shape: [roomW, roomDepth, .1], pos: [wShift, roomDim.z_min - .06, roomDepth / 2]},
      {name: "ceiling", shape: [roomW, roomDepth, 3], pos: [wShift, roomDim.z_max, roomDepth / 2]},
      {name: "bed", shape: [bedW, bedDepth, Math.max(bedH, 0.01)], pos: [bedWShift, bedHShift, bedDepthShift]},
      {
        name: "bedRegion",
        shape: [bedW, bedDepth, Math.max(bedRegionH, 0.01)],
        pos: [bedWShift, bedRegionHShift, bedDepthShift]
      },
      {name: "win1", pos: [wShift - roomW / 2 + 0.01, hShift, roomDepth / 2]},
      {
        name: "device1", type: ItemType.Device,
        shape: [0.1, .04, 0.1], pos: [0, 0, 0.02], rot: [0, 0, 0],
        meta: {radarDirection: [0, 0, 1], hRange: 60, vRange: 55}
      },
    ]

    if (Array.isArray(noiseRegions)) {
      for (let i = 0; i < noiseRegions.length; i++) {
        residentItems.push(buildNoiseRegionItem(i, noiseRegions[i]))
      }
    }

    removeResidentItemPrefix(NOISE_REGION_PREFIX)
    updateResident(residentItems as ItemDTO[])

    itemService.BuildItems().then(items => setItems(items))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentHash])
  return [items];
}

function buildNoiseRegionItem(i: number, r: DimensionDTO) {
  if (r.x_max == null) r.x_max = UNBOUND_DIMENSION
  if (r.x_min == null) r.x_min = -UNBOUND_DIMENSION
  if (r.y_max == null) r.y_max = UNBOUND_DIMENSION
  if (r.y_min == null) r.y_min = -UNBOUND_DIMENSION
  if (r.z_max == null) r.z_max = UNBOUND_DIMENSION
  if (r.z_min == null) r.z_min = -UNBOUND_DIMENSION
  const w = Math.max(Math.abs(r.x_max - r.x_min), 0.1)
  const d = Math.max(Math.abs(r.y_max - r.y_min), 0.1)
  const h = Math.max(Math.abs(r.z_max - r.z_min), 0.1)
  const wShift = w / 2 + r.x_min
  const dShift = d / 2 + r.y_min
  const hShift = h / 2 + r.z_min
  return {
    name: `${NOISE_REGION_PREFIX}${i}`,
    type: ItemType.Region,
    shape: [w, d, h],
    pos: [wShift, hShift, dShift],
    rot: [0, 0, 0],
  }
}