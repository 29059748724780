// source: device_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.relay.CloudPoint', null, global);
goog.exportSymbol('proto.relay.DeviceData', null, global);
goog.exportSymbol('proto.relay.UnifiedVital', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relay.DeviceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relay.DeviceData.repeatedFields_, null);
};
goog.inherits(proto.relay.DeviceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relay.DeviceData.displayName = 'proto.relay.DeviceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relay.UnifiedVital = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.relay.UnifiedVital.repeatedFields_, null);
};
goog.inherits(proto.relay.UnifiedVital, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relay.UnifiedVital.displayName = 'proto.relay.UnifiedVital';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.relay.CloudPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.relay.CloudPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.relay.CloudPoint.displayName = 'proto.relay.CloudPoint';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relay.DeviceData.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relay.DeviceData.prototype.toObject = function(opt_includeInstance) {
  return proto.relay.DeviceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relay.DeviceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.DeviceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pointCloudList: jspb.Message.toObjectList(msg.getPointCloudList(),
    proto.relay.CloudPoint.toObject, includeInstance),
    vitalsList: jspb.Message.toObjectList(msg.getVitalsList(),
    proto.relay.UnifiedVital.toObject, includeInstance),
    recordedAt: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relay.DeviceData}
 */
proto.relay.DeviceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relay.DeviceData;
  return proto.relay.DeviceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relay.DeviceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relay.DeviceData}
 */
proto.relay.DeviceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new proto.relay.CloudPoint;
      reader.readMessage(value,proto.relay.CloudPoint.deserializeBinaryFromReader);
      msg.addPointCloud(value);
      break;
    case 3:
      var value = new proto.relay.UnifiedVital;
      reader.readMessage(value,proto.relay.UnifiedVital.deserializeBinaryFromReader);
      msg.addVitals(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRecordedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relay.DeviceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relay.DeviceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relay.DeviceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.DeviceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPointCloudList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.relay.CloudPoint.serializeBinaryToWriter
    );
  }
  f = message.getVitalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.relay.UnifiedVital.serializeBinaryToWriter
    );
  }
  f = message.getRecordedAt();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 device_id = 1;
 * @return {number}
 */
proto.relay.DeviceData.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.DeviceData} returns this
 */
proto.relay.DeviceData.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated CloudPoint point_cloud = 2;
 * @return {!Array<!proto.relay.CloudPoint>}
 */
proto.relay.DeviceData.prototype.getPointCloudList = function() {
  return /** @type{!Array<!proto.relay.CloudPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relay.CloudPoint, 2));
};


/**
 * @param {!Array<!proto.relay.CloudPoint>} value
 * @return {!proto.relay.DeviceData} returns this
*/
proto.relay.DeviceData.prototype.setPointCloudList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.relay.CloudPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relay.CloudPoint}
 */
proto.relay.DeviceData.prototype.addPointCloud = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.relay.CloudPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relay.DeviceData} returns this
 */
proto.relay.DeviceData.prototype.clearPointCloudList = function() {
  return this.setPointCloudList([]);
};


/**
 * repeated UnifiedVital vitals = 3;
 * @return {!Array<!proto.relay.UnifiedVital>}
 */
proto.relay.DeviceData.prototype.getVitalsList = function() {
  return /** @type{!Array<!proto.relay.UnifiedVital>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.relay.UnifiedVital, 3));
};


/**
 * @param {!Array<!proto.relay.UnifiedVital>} value
 * @return {!proto.relay.DeviceData} returns this
*/
proto.relay.DeviceData.prototype.setVitalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.relay.UnifiedVital=} opt_value
 * @param {number=} opt_index
 * @return {!proto.relay.UnifiedVital}
 */
proto.relay.DeviceData.prototype.addVitals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.relay.UnifiedVital, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relay.DeviceData} returns this
 */
proto.relay.DeviceData.prototype.clearVitalsList = function() {
  return this.setVitalsList([]);
};


/**
 * optional double recorded_at = 4;
 * @return {number}
 */
proto.relay.DeviceData.prototype.getRecordedAt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.DeviceData} returns this
 */
proto.relay.DeviceData.prototype.setRecordedAt = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.relay.UnifiedVital.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relay.UnifiedVital.prototype.toObject = function(opt_includeInstance) {
  return proto.relay.UnifiedVital.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relay.UnifiedVital} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.UnifiedVital.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    azimuth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    hr: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rr: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hrfft: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hrpp: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hrcm: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hrcorr: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rrfft: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rrpp: jspb.Message.getFieldWithDefault(msg, 11, 0),
    rrcm: jspb.Message.getFieldWithDefault(msg, 12, 0),
    rrcorr: jspb.Message.getFieldWithDefault(msg, 13, 0),
    rwfList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 14)) == null ? undefined : f,
    hwfList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relay.UnifiedVital}
 */
proto.relay.UnifiedVital.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relay.UnifiedVital;
  return proto.relay.UnifiedVital.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relay.UnifiedVital} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relay.UnifiedVital}
 */
proto.relay.UnifiedVital.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAzimuth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAltitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHr(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRr(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHrfft(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHrpp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHrcm(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHrcorr(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRrfft(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRrpp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRrcm(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRrcorr(value);
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRwf(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHwf(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relay.UnifiedVital.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relay.UnifiedVital.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relay.UnifiedVital} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.UnifiedVital.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAzimuth();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHr();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRr();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getRwfList();
  if (f.length > 0) {
    writer.writePackedFloat(
      14,
      f
    );
  }
  f = message.getHwfList();
  if (f.length > 0) {
    writer.writePackedFloat(
      15,
      f
    );
  }
};


/**
 * optional double range = 1;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double azimuth = 2;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getAzimuth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setAzimuth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double altitude = 3;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 hr = 4;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getHr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHr = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 rr = 5;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRr = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 hrfft = 6;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getHrfft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHrfft = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearHrfft = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasHrfft = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 hrpp = 7;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getHrpp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHrpp = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearHrpp = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasHrpp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 hrcm = 8;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getHrcm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHrcm = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearHrcm = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasHrcm = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 hrcorr = 9;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getHrcorr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHrcorr = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearHrcorr = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasHrcorr = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 rrfft = 10;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRrfft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRrfft = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearRrfft = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasRrfft = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 rrpp = 11;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRrpp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRrpp = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearRrpp = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasRrpp = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 rrcm = 12;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRrcm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRrcm = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearRrcm = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasRrcm = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 rrcorr = 13;
 * @return {number}
 */
proto.relay.UnifiedVital.prototype.getRrcorr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRrcorr = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearRrcorr = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.relay.UnifiedVital.prototype.hasRrcorr = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated float rwf = 14;
 * @return {!Array<number>}
 */
proto.relay.UnifiedVital.prototype.getRwfList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setRwfList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.addRwf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearRwfList = function() {
  return this.setRwfList([]);
};


/**
 * repeated float hwf = 15;
 * @return {!Array<number>}
 */
proto.relay.UnifiedVital.prototype.getHwfList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.setHwfList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.addHwf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.relay.UnifiedVital} returns this
 */
proto.relay.UnifiedVital.prototype.clearHwfList = function() {
  return this.setHwfList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.relay.CloudPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.relay.CloudPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.relay.CloudPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.CloudPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    velocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    magnitude: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.relay.CloudPoint}
 */
proto.relay.CloudPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.relay.CloudPoint;
  return proto.relay.CloudPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.relay.CloudPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.relay.CloudPoint}
 */
proto.relay.CloudPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelocity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMagnitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.relay.CloudPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.relay.CloudPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.relay.CloudPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.relay.CloudPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMagnitude();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional float x = 1;
 * @return {number}
 */
proto.relay.CloudPoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.CloudPoint} returns this
 */
proto.relay.CloudPoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float y = 2;
 * @return {number}
 */
proto.relay.CloudPoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.CloudPoint} returns this
 */
proto.relay.CloudPoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float z = 3;
 * @return {number}
 */
proto.relay.CloudPoint.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.CloudPoint} returns this
 */
proto.relay.CloudPoint.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float velocity = 4;
 * @return {number}
 */
proto.relay.CloudPoint.prototype.getVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.relay.CloudPoint} returns this
 */
proto.relay.CloudPoint.prototype.setVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 magnitude = 5;
 * @return {number}
 */
proto.relay.CloudPoint.prototype.getMagnitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.relay.CloudPoint} returns this
 */
proto.relay.CloudPoint.prototype.setMagnitude = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


goog.object.extend(exports, proto.relay);
