import React from 'react'
import formatISO9075 from 'date-fns/formatISO9075'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import addSeconds from 'date-fns/addSeconds'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Slider from "@mui/material/Slider";
import "./InfoCard.css"
import {Source} from "./device-data.service";

interface IPlayerPanel {
  source: Source
  currentTime: Date | null
  onTimeSelection: any
}

const PlayerPanel: React.FC<IPlayerPanel> = ({source, currentTime, onTimeSelection}) => {
  const [isDrag, setIsDrag] = React.useState(false)
  const [dragValue, setDragValue] = React.useState(0)
  const totalSec = differenceInSeconds(source.dataTime.end, source.dataTime.start)
  const curSec = differenceInSeconds(currentTime || source.dataTime.start, source.dataTime.start)
  const midTime = addSeconds(source.dataTime.start, totalSec / 2)
  const marks = [
    {value: 0, label: formatISO9075(source.dataTime.start)},
    {value: totalSec / 2, label: formatISO9075(midTime)},
    {value: totalSec, label: formatISO9075(source.dataTime.end)},
  ];

  const handleChange = (e: any, atSecond: number | number[]) => {
    atSecond = Array.isArray(atSecond) ? atSecond[0] : atSecond
    setDragValue(atSecond)
  }

  const handleSliderCommit = (e: any, atSecond: number | number[]) => {
    atSecond = Array.isArray(atSecond) ? atSecond[0] : atSecond
    onTimeSelection(atSecond)
  }

  const percentToTimeStr = (atSecond: number) => {
    const atTime = addSeconds(source.dataTime.start, atSecond)
    return formatISO9075(atTime)
  }

  return (
    <Box sx={{position: 'absolute', bottom: 16, right: 16, zIndex: (theme) => theme.zIndex.drawer + 2}}>
      <Card variant="outlined" sx={{background: 'rgba(255,255,255,.7)'}}>
        <CardContent sx={{px: 10, py: 5, minWidth: 500}}>
          {totalSec && <Slider
              aria-label="Frame Location"
              min={0}
              max={totalSec}
              step={1}
              valueLabelDisplay="on"
              marks={marks}
              value={isDrag ? dragValue : curSec}
              valueLabelFormat={percentToTimeStr}
              onChangeCommitted={handleSliderCommit}
              onChange={handleChange}
              onMouseDown={() => setIsDrag(true)}
              onMouseUp={() => setIsDrag(false)}
          />
          }
        </CardContent>
      </Card>
    </Box>
  )
}
export default PlayerPanel