import {Pose} from "./Types";

export enum ItemType {
  Object = "object",
  Region = "region",
  Wall = "wall",
  Device = "device",
  Person = "person",
  Ground = "ground",
  Window = "window",
}

export class Item {
  constructor(
    public id: string,
    public name: string,
    public type: ItemType,
    public pose: Pose,
    public shape: any,
    public meta?: any) {
  }
}
