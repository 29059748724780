import React from 'react'
import {Chart} from "chart.js"
import {createTimeChart} from '../services/chart.service'

interface IVitalChart {
    title?: string
    magnitudes: number[]
    deviceId: string | undefined
    color: string
    min?: number
    max?: number
}

const maxCount = 300

const VitalChart: React.FC<IVitalChart> = (
    {title, magnitudes, deviceId, color, min, max}
) => {
    const chartBoard = React.useRef<HTMLCanvasElement | null>(null);
    const [chart, setChart] = React.useState<Chart | null>(null);

    React.useEffect(() => {
        if (!chartBoard.current) return
        let size: any = {}
        if (min !== undefined) size['min'] = min
        if (max !== undefined) size['max'] = max
        const c = createTimeChart(chartBoard.current as HTMLCanvasElement, title, maxCount, color, size)
        setChart(c)

        return () => {
            c.destroy()
            setChart(null)
        }
    }, [title, deviceId, color, chartBoard])

    React.useEffect(() => {
        if (!chart) return
        chart.data.datasets[0].data = magnitudes
        chart.canvas && chart.update()
    }, [chart, magnitudes])

    return <>
        <canvas ref={chartBoard} width="400" height="100"/>
    </>
}
export default VitalChart
