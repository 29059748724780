import {useState, useEffect} from 'react';

export function useServerConfig(): [string] {
  const [wsHost, setWsHost] = useState<string>(window.location.hostname)

  useEffect(() => {
    setWsHost('localhost')
    return
    fetch('/server.json')
      .then(r => r.json())
      .then(data => {
        if (!data || !data["host"]) return
        setWsHost(data["host"])
      })
      .catch(e => {
        if (!e.toString().startsWith("SyntaxError: Unexpected token '<'")) {
          console.log("error from reaching server.json:", e)
        }
      })
  }, [])

  return [wsHost];
}