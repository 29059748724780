import React from 'react'
import './logo.css'

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <img src={process.env.PUBLIC_URL + '/tellus-logo.svg'} alt="Tellus Logo" className="logo-image" />
    </div>
  );
};

export default Logo