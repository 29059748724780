export class DimensionDTO {
    constructor(
        public x_max: number,
        public x_min: number,
        public y_max: number,
        public y_min: number,
        public z_max: number,
        public z_min: number,
    ) {
    }

    serialize(): string {
        return JSON.stringify([this.x_max, this.x_min, this.y_max, this.y_min, this.z_max, this.z_min]);
    }

    static deserialize(serialized: string): DimensionDTO {
        const v = JSON.parse(serialized);
        return new DimensionDTO(v[0], v[1], v[2], v[3], v[4], v[5]);
    }

    static fromDict(d: any): DimensionDTO | undefined {
        if (!d) return undefined
        const {x_max, x_min, y_max, y_min, z_max, z_min} = d;
        return new DimensionDTO(x_max, x_min, y_max, y_min, z_max, z_min);
    }
}
