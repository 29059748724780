import * as B from "babylonjs"
import {LineChart} from "./LineChart";
import _sum from "lodash-es/sum"

export class Blackboard implements B.IDisposable {
  private fpsChart: LineChart
  private timerChart: LineChart
  private vitalChart: LineChart
  private userTrackingTimerChart: LineChart
  private readonly _chartW: number
  private readonly _chartH: number
  private readonly _fullPicture

  constructor(private _textures: B.DynamicTexture[], private _widths: number[], private _h: number) {
    this._fullPicture = document.createElement('canvas')
    this._fullPicture.height = this._h
    this._fullPicture.width = this._h * 2

    const chartMaxW = _sum(_widths) / 2.3 * 0.9, chartMaxH = _h / 2 * 0.9
    const chartMinH = _h / 3, chartMinW = chartMinH * 1.5
    const isWideEnough = chartMaxH * 1.5 < chartMaxW
    const isTooNarrow = chartMaxW < chartMinH * 1.5
    this._chartW = isWideEnough ? chartMaxH * 1.5 : isTooNarrow ? chartMinW : chartMaxW
    this._chartH = isWideEnough ? chartMaxH : isTooNarrow ? chartMinH : chartMaxW / 2

    this.fpsChart = new LineChart('FPS/Points', this._chartW, this._chartH, ['fps', 'points'])
    this.timerChart = new LineChart('Algorithms time (milliseconds)', this._chartW * 1.2, this._chartH,
      ['UserTracking', 'in_bed_ml', 'in_room', 'in_room_ml', 'Asleep', 'VitalTracking'], true)
    this.vitalChart = new LineChart('Vitals', this._chartW, this._chartH, ['rr', 'hr'])
    this.userTrackingTimerChart = new LineChart('UserTracking time (milliseconds)', this._chartW * 1.2, this._chartH,
      ['meanShift', 'tracking', 'output', 'other'], true)
  }

  dispose() {
    this.fpsChart.dispose()
    this.timerChart.dispose()
    this.vitalChart.dispose()
    this.userTrackingTimerChart.dispose()
  }

  updateFrame(fps: number) {
    if (!Array.isArray(this._textures) || this._textures.length < 1) return

    const cvs = this._fullPicture
    const ctx = cvs.getContext('2d') as CanvasRenderingContext2D
    ctx.clearRect(0, 0, cvs.width, cvs.height);

    this.fpsChart.add({fps})

    const wGap = this._chartW * .05,
      hGap = this._chartH * .1,
      dx2 = this._chartW + wGap * 2,
      dy2 = this._chartH + hGap * 2
    ctx.drawImage(this.fpsChart.getCanvas(), wGap, hGap, this._chartW, this._chartH)
    ctx.drawImage(this.vitalChart.getCanvas(), wGap, dy2, this._chartW, this._chartH)
    ctx.drawImage(this.timerChart.getCanvas(), dx2, hGap, this._chartW, this._chartH)
    ctx.drawImage(this.userTrackingTimerChart.getCanvas(), dx2, dy2, this._chartW, this._chartH)

    this.updateTextures()
  }

  updateTextures() {
    let sx = 0
    for (const [i, tex] of this._textures.entries()) {
      tex.getContext().fillStyle = '#010101'
      tex.clear()
      tex.getContext().drawImage(this._fullPicture, sx, 0, this._widths[i], this._h, 0, 0, this._widths[i], this._h)
      sx += this._widths[i]
      tex.update(true)
    }
  }

  setTimers(timers: Record<string, any>) {
    this.timerChart.add(timers)
  }

  setUserTrackingTimers(timers: Record<string, any>) {
    this.userTrackingTimerChart.add(timers)
  }

  setVitals(vitals: { hr: number, rr: number }) {
    this.vitalChart.add(vitals)
  }

  setPoints(points: number) {
    this.fpsChart.add({points: points})
  }
}