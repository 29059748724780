import * as B from "babylonjs";
import {ItemType} from "../../core/entities/Item";
import {ItemServiceImpl} from "../../core/usecases/ItemService";
import {ItemRepositoryImpl} from "../../core/infrastructure/ItemRepositoryImpl";
import Centroids from "./Centroids"

const itemService = new ItemServiceImpl(new ItemRepositoryImpl())

export default class Vitals extends Centroids {
  cloudMeshes: B.InstancedMesh[] = []
  masterMesh: B.Mesh | null = null

  protected buildMeshes(count: number, diameter: number) {
    itemService.AddItem("person-vitals",
      ItemType.Person,
      [diameter],
      [0, 0, 0],
      [0, 0, 0],
      {visibility: 1}
    ).then(item => {
      this.masterMesh = item.shape as B.Mesh
      this.masterMesh.registerInstancedBuffer("color", 3)
      this.masterMesh.instancedBuffers.color = B.Color3.Green()
      this.masterMesh.isVisible = false
      for (let i = 0; i < count; i++) {
        const mi = this.masterMesh.createInstance("i" + i)
        mi.isVisible = false
        this.cloudMeshes.push(mi)
      }
      return this.cloudMeshes
    })
  }

  public formatLabel(point: any): string {
    const heartRateStr = Vitals.f(point['heartRate'], 0) || '--'
    const respiratoryRateStr = Vitals.f(point['respiratoryRate'], 0) || '--'
    return super.formatLabel(point) + `, hr:${heartRateStr}, rr:${respiratoryRateStr}`
  }
}
