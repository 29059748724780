import {DimensionDTO} from "./DimensionDTO";

export class DeviceMetaDTO {
  constructor(
    public bed_dimension: DimensionDTO,
    public device_id: number,
    public id: number,
    public room_id: number,
    public room_dimension: DimensionDTO,
    public noise_regions: DimensionDTO[],
  ) {
  }
}

export const DEFAULT_DEVICE_META = new DeviceMetaDTO(
    new DimensionDTO(0, 0, 0, 0, 0, 0),
    -1,
    -1,
    -1,
    new DimensionDTO(1.7, -1.7, 3, 0, 1, -1),
    []
)