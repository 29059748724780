import {useEffect, useState} from 'react'
import {fromEvent} from 'rxjs'
import {filter} from 'rxjs/operators'

class KeyCombination {
  constructor(
    public ctrl: boolean,
    public shift: boolean,
    public alt: boolean,
    public key: string,
  ) {
  }

  public equal(kc: KeyCombination): boolean {
    return this.ctrl === kc.ctrl && this.alt === kc.alt && this.shift === kc.shift && this.key === kc.key
  }
}

export const hotkeys: Record<string, KeyCombination> = {
  'ctrl+p': new KeyCombination(true, false, false, 'p'), // presentation mode
  'ctrl+o': new KeyCombination(true, false, false, 'o'), // original mode
  'ctrl+h': new KeyCombination(true, false, false, 'h'), // show/hide UI
  'ctrl+1': new KeyCombination(true, false, false, '1'), // raw data mode
  'ctrl+2': new KeyCombination(true, false, false, '2'), // inference mode
}

export function useKeyDownEvent(base: any = document): [KeyCombination | null] {
  const [keyEvent, setKeyEvent] = useState<KeyCombination | null>(null);

  useEffect(() => {
    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')
    const subscription = keyDown$
      .pipe(filter((event) => event.key !== 'Control' && event.key !== 'Shift' && event.key !== 'Alt'))
      .subscribe((event) => {
        const combination = new KeyCombination(event.ctrlKey, event.shiftKey, event.altKey, event.key)

        for (const key in hotkeys) {
          if (Object.prototype.hasOwnProperty.call(hotkeys, key)) {
            if (combination.equal(hotkeys[key])) {
              event.preventDefault()
              setKeyEvent(combination)
              break
            }
          }
        }
      });

    return () => {
      subscription.unsubscribe()
    };
  }, []);

  return [keyEvent]
}
