interface Frame {
  // Define the properties of the frame here if needed.
}

export class FrameQueue {
  private queue: Frame[] = [];
  private lastPopTime: number = 0;

  private getItemsPerSecond(): number {
    const itemCount = this.queue.length;
    if (itemCount < 4) return 1;
    if (itemCount < 8) return 2;
    if (itemCount < 16) return 4;
    if (itemCount < 24) return 5;
    if (itemCount < 32) return 6;
    return 10;
  }

  pushFrame(frame: Frame): void {
    this.queue.push(frame);
  }

  elapsed(): number {
    return Date.now() - this.lastPopTime
  }

  isReady(): boolean {
    if (this.queue.length === 0) return false;

    const itemsPerSecond = this.getItemsPerSecond();
    const elapsedTimeSinceLastPop = Date.now() - this.lastPopTime;
    const expectedElapsedTime = 1000 / itemsPerSecond;

    return elapsedTimeSinceLastPop >= expectedElapsedTime;
  }

  popFrame(): Frame | undefined {
    if (this.queue.length === 0) return undefined;

    this.lastPopTime = Date.now();
    return this.queue.shift();
  }

  purge() {
    this.queue.length = 0
  }
}

export const frameQ = new FrameQueue()
