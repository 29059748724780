import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import '@aws-amplify/ui-react/styles.css'
import './index.css';
import App from './App';

import Amplify from "aws-amplify";
import config from "./general-config.json";
import {Authenticator, Button, Heading, useTheme} from '@aws-amplify/ui-react'

Amplify.configure(config.Amplify)

const Header = () => {
  const {tokens} = useTheme();
  return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={5}>
    Sign in with your Tellus admin account
  </Heading>
}
const Footer = () => <></>

const AuthStateApp = () => {
  const [username, setUsername] = React.useState<string>();

  if (config.Develop) {
    return <AppRouter username="local"/>
  } else {
    return <Authenticator components={{SignIn: {Header, Footer}}} className="fill-screen">{
      ({signOut, user}) => {
        user?.getUserAttributes((err, attrs) => {
          if (err || !attrs) return console.log("Error getting user attributes", err)
          const familyName = attrs.find(attr => attr.Name === "family_name")?.Value
          const givenName = attrs.find(attr => attr.Name === "given_name")?.Value
          const fullname = (familyName || givenName) && `${familyName}, ${givenName}`
          setUsername(fullname || "Unknown Identity")
        })
        return <>
          {username ? <AppRouter username={username}/> : <></>}
          <Button variation="primary" onClick={signOut} className="float-button">Sign out</Button>
        </>
      }
    }</Authenticator>
  }
}

function AppRouter({username}: { username: string }) {
  return (<Routes>
    <Route path="/" element={<App username={username}/>}>
      <Route path="source/:sourceName" element={<App username={username}/>}/>
      <Route path="source/:sourceName/device/:deviceId" element={<App username={username}/>}/>
      <Route path="*" element={<App username={username}/>}/>
    </Route>
  </Routes>);
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <AuthStateApp/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
