import {useState, useEffect} from 'react';
import * as B from "babylonjs"
import {Blackboard} from "./Blackboard";
import _map from "lodash-es/map"


interface Texture {
  tex: B.DynamicTexture
  width: number
  height: number
}

export function useBoard(): [any, Blackboard | null] {
  const [boardTex, setBoardTex] = useState<Texture[] | null>(null)
  const [board, setBoard] = useState<Blackboard | null>(null)

  useEffect(() => {
    if (!boardTex) return
    const b = new Blackboard(_map(boardTex, "tex"), _map(boardTex, "width"), boardTex[0].height)
    setBoard(b)
    return () => {
      b.dispose()
      setBoard(null)
    }
  }, [boardTex])

  return [setBoardTex, board]
}