import {useState, useEffect, useCallback} from 'react';

let ws_store: WebSocket | null = null

export function useWebsocket(uriPath: string, username: string): [WebSocket | null, Record<string, any>, string] {
  const [ws, setWs] = useState<WebSocket | null>(null)
  const [json, setJson] = useState<Record<string, any>>({})
  const [countdown, setCountdown] = useState<number>(1)
  const [aliveCountdown, setAliveCountdown] = useState<number>(0)
  const [message, setMessage] = useState<string>("")

  const protocol = uriPath.startsWith('localhost') || uriPath.startsWith('127.0.0.1') ? "ws://" : "wss://"
  const url = protocol + uriPath
  const connect = useCallback(() => {
    if (ws_store?.readyState === WebSocket.OPEN) {
      setWs(ws_store)
      setCountdown(-2)
      setAliveCountdown(10)
      return
    }
    if (
      ws_store != null
      && ws_store.url === url
      && ![WebSocket.CLOSED, WebSocket.CLOSING].includes(ws_store.readyState)
    ) {
      console.warn("something wrong. ws_store:", ws_store, "ws_store.url", ws_store?.url)
      let state = "unknown"
      switch (ws_store?.readyState) {
        case WebSocket.CLOSED:
          state="closed"
          break
        case WebSocket.CONNECTING:
          state="connecting"
          break
        case WebSocket.CLOSING:
          state="closing"
          break
      }
      console.warn('the ready state is', state, ws_store?.readyState)
      return
    }
    console.log("connecting to data server:", url)
    ws_store = new WebSocket(url)
    ws_store.onopen = (ev) => {
      console.log("connected")
      setWs(ws_store)
      setCountdown(-2)
      setAliveCountdown(10)
      ws_store?.send("name:" + username)
    }
    ws_store.onclose = () => {
      setWs(null)
      setCountdown(5)
    }
    ws_store.onerror = (ev) => console.log("connection err", ev)
    ws_store.onmessage = (msgEv) => {
      if (msgEv.data === "alive") return setAliveCountdown(10)
      setJson(JSON.parse(msgEv.data))
    }
    return () => ws_store?.close(1000)
  }, [url, username])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (countdown > 0) {
      timeoutId = setTimeout(() => setCountdown(cd => cd - 1), 1000)
    } else if (countdown === 0) {
      connect()
    }
    return () => clearTimeout(timeoutId)
  }, [countdown, connect])

  // check alive
  useEffect(() => {
    if (countdown !== -2) return
    const sendCheck = setInterval(() => ws?.send("alive-check"), 5000)
    const aliveTick = setInterval(() => setAliveCountdown(cd => cd - 1), 1000)
    return () => {
      clearInterval(sendCheck)
      clearInterval(aliveTick)
    }
  }, [ws, countdown])

  useEffect(() => {
    if (aliveCountdown < 0) {
      setWs(null)
      setCountdown(5)
    }
  }, [aliveCountdown])

  useEffect(() => {
    if (countdown > 0) {
      setMessage(`Connecting to data server in ${countdown} seconds`)
    } else if (countdown === 0) {
      setMessage(`Connecting to data server...`)
    } else if (aliveCountdown % 2 === 0) {
      setMessage("Alive")
    } else {
      setMessage("*Alive")
    }
  }, [countdown, aliveCountdown])

  return [ws, json, message];
}