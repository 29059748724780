import _get from "lodash-es/get"
import _isEqual from "lodash-es/isEqual"
import formatTime from 'date-fns/formatISO9075'
import fromUnixTime from 'date-fns/fromUnixTime'
import React, {useEffect, useState} from "react";
import * as B from "babylonjs"
import * as GUI from "babylonjs-gui"
import "./Visualizer.css"
import {Item, ItemType} from "../core/entities/Item";
import {
  addGuiText,
  CAMERA_TARGET_0,
  convertMeterToPx,
  createGui,
  createScene,
  drawDevice,
  drawFloor,
  makeCheckbox,
  makeGuiButton,
  makeGuiSlider,
  makeGuiText,
  makePanel,
  makeWinLight,
  setAxisVisibility, setShadower,
  updateAxes
} from "./scene.service";
import {clearAnimation, startAnimation, stopAnimation} from "./animation.service";
import {arrowClouds, centroidClouds, pointClouds, vitalClouds} from "./clouds.service";
import {useBoard} from "./board.hook";
import {DeviceMetaDTO} from "../core/infrastructure/dtos/DeviceMetaDTO";
import {RoomType} from "./elements/RoomType";
import {DimensionDTO} from "../core/infrastructure/dtos/DimensionDTO";
import {fmtVersions, fmtVital} from "./utils";

const ctrlGroup = new GUI.Container();
const pauseBtn = makeGuiButton("Pause User", 3, 53)
const topDownBtn = makeGuiButton("Topdown View", 3, 163, 30)
const perspectiveBtn = makeGuiButton("Perspective View", 3, 203, 30)
const frameHeader = makeGuiText(5, 103, "left")
const frameSlider = makeGuiSlider(3, 123);
const messageBox = makeGuiText(-3, 3)
const START_SIM_TEXT = "Ray Obstruction Test"
const simulatorBtn = makeGuiButton(START_SIM_TEXT, 3, 3, 25)
const inBedText = addGuiText("InBed", 30, -20, 60)
const inRoomText = addGuiText("InRoom", 200, -20, 60)
const asleepText = addGuiText("Asleep", 410, -20, 60)
const fallenText = addGuiText("Fallen", 600, -20, 60)
const algorithmVersionText = addGuiText(fmtVersions(), 30, -5, 16)
const brText = addGuiText("", 30, -20, 60)
const hrText = addGuiText("", 220, -20, 60)
const dataTimeText = addGuiText("", 530, -5, 16)

export function showPredictionClasses(isShow: boolean = true) {
  inBedText.isVisible = inRoomText.isVisible = asleepText.isVisible = fallenText.isVisible = isShow
}

export function setPredictionClasses(isLightTheme: boolean, {in_bed = false, in_room = false, asleep = false, fallen = false}) {
  const positiveColor = isLightTheme? '#4E68DE': '#8C9FF2'
  inBedText.color = in_bed ? positiveColor : "#2a2a2a"
  inRoomText.color = in_room ? positiveColor : "#2a2a2a"
  asleepText.color = asleep ? positiveColor : "#2a2a2a"
  fallenText.color = fallen ? positiveColor : "#2a2a2a"
}

interface IVisualizer {
  theme: string,
  cleanUi: boolean,
  points: any[],
  roomDimensionPayload: string | undefined,
  predictions: Record<string, any>,
  timers: Record<string, any>,
  versions: Record<string, any>
  items: Item[],
  residents: DeviceMetaDTO[],
  fps: number,
  updateTick: number,
  vitals: { rr: number, hr: number }
  generatedVitals: Record<string, number> | null
  wsMessage: string,
  pause: boolean,
  onPause: (isUnPausing: boolean) => void,
}

export const Visualizer: React.FC<IVisualizer> = (
  {
    theme,
    cleanUi,
    points,
    roomDimensionPayload,
    predictions,
    timers,
    versions,
    items,
    residents,
    fps,
    updateTick,
    vitals,
    generatedVitals,
    wsMessage,
    pause,
    onPause,
  }) => {
  const [scene, setScene] = useState<B.Scene>()
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false)
  const [shouldReset, setShouldReset] = useState<boolean>(false)
  const [pauseBtnState, setPauseBtnState] = useState<boolean | null>(null)
  const [framesShow, setFramesShow] = useState<number>(0)
  const [showVelocity, setShowVelocity] = useState<boolean>(true)
  const [showAxis, setShowAxis] = useState<boolean>(false)
  const [setBoardTex, board] = useBoard()
  const prevRoomDimRef = React.useRef<DimensionDTO>();

  const handleClearAnimation = () => {
    stopAnimation()
    clearAnimation()
    setShouldAnimate(false)
    setShouldReset(false)
    if (simulatorBtn.textBlock) simulatorBtn.textBlock.text = START_SIM_TEXT
  }

  useEffect(() => {
    if (!roomDimensionPayload) return
    const dim = DimensionDTO.deserialize(roomDimensionPayload)
    if (!_isEqual(prevRoomDimRef.current, dim)) {
      prevRoomDimRef.current = dim;
      updateAxes(dim.x_min, dim.z_min, dim.y_min, dim.x_max, dim.z_max, dim.y_max)

      if (!showAxis) {
        setAxisVisibility(true)
        const timeoutId = setTimeout(() => setAxisVisibility(false), 10000)
        return () => clearTimeout(timeoutId)
      }
    }
    setAxisVisibility(showAxis)
  }, [roomDimensionPayload, showAxis])

  useEffect(() => {
    messageBox.text = wsMessage
  }, [wsMessage])

  useEffect(() => {
    if (!shouldAnimate || !items) {
      setShouldAnimate(false)
      return
    }
    startAnimation(items)
    if (simulatorBtn.textBlock) simulatorBtn.textBlock.text = "Stop"
    return handleClearAnimation
  }, [shouldAnimate, items])

  useEffect(() => {
    if (shouldReset) handleClearAnimation()
  }, [shouldReset])

  useEffect(() => {
    const txtBlock = pauseBtn.textBlock
    if (txtBlock) txtBlock.text = pause ? "Unpause User" : "Pause User"
    setPauseBtnState(null)
  }, [pause])

  useEffect(() => {
    if (!pause === pauseBtnState) onPause(pauseBtnState)
  }, [onPause, pauseBtnState, pause])

  // 3D UI event registration
  useEffect(() => {
    const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement
    const options = {preserveDrawingBuffer: true, stencil: true}
    const engine = new B.Engine(canvas, true, options)

    const scene = createScene(canvas, engine)
    engine.runRenderLoop(() => scene.render())
    setScene(scene)

    const ui = createGui('TextUI')

    ui.addControl(inBedText)
    ui.addControl(inRoomText)
    ui.addControl(asleepText)
    ui.addControl(fallenText)
    ui.addControl(algorithmVersionText)
    ui.addControl(brText)
    ui.addControl(hrText)
    ui.addControl(dataTimeText)
    ui.addControl(ctrlGroup)

    const startOb = simulatorBtn.onPointerUpObservable.add(() => {
      const txtBlock = simulatorBtn.textBlock
      if (txtBlock?.text === START_SIM_TEXT) {
        setShouldAnimate(true)
      } else if (txtBlock?.text === "Stop") {
        stopAnimation()
        txtBlock.text = "Clear"
      } else {
        setShouldReset(true)
      }
    });
    ctrlGroup.addControl(simulatorBtn);

    const pauseBtnOb = pauseBtn.onPointerUpObservable.add(() => {
      const txtBlock = pauseBtn.textBlock
      if (txtBlock?.text === "Pause User") setPauseBtnState(true)
      else setPauseBtnState(false)
    });
    ctrlGroup.addControl(pauseBtn);

    const onFrameSliderChange = (val: number) => {
      frameHeader.text = "Frames in view: " + val
      setFramesShow(val)
    }
    onFrameSliderChange(frameSlider.value)
    ctrlGroup.addControl(frameHeader);
    frameHeader.color='black'
    frameHeader.fontStyle = 'bold'

    const sliderOb = frameSlider.onValueChangedObservable.add(onFrameSliderChange)
    ctrlGroup.addControl(frameSlider);

    ctrlGroup.addControl(messageBox);

    const topDownBtnOb = topDownBtn.onPointerUpObservable.add(() => {
      const camera = scene.getCameraByName("camera1") as B.ArcRotateCamera
      camera.target.copyFrom(CAMERA_TARGET_0)
      camera.alpha = -Math.PI / 2
      camera.beta = 0
      camera.radius = 6
    });
    ctrlGroup.addControl(topDownBtn);

    const viewPerspective = () => {
      const camera = scene.getCameraByName("camera1") as B.ArcRotateCamera
      camera.target.copyFrom(CAMERA_TARGET_0)
      camera.alpha = -Math.PI * 8 / 16
      camera.beta = Math.PI * 7 / 16
      camera.radius = 4
    }
    const perspectiveBtnOb = perspectiveBtn.onPointerUpObservable.add(viewPerspective);
    ctrlGroup.addControl(perspectiveBtn);
    viewPerspective()

    const velocityPanel = makePanel(5, 253)
    const velocityCheckbox = makeCheckbox()
    const velocityOb = velocityCheckbox.onIsCheckedChangedObservable.add(v => setShowVelocity(v));
    velocityPanel.addControl(velocityCheckbox);
    const velocityHeader = makeGuiText(null, null, "left")
    velocityHeader.text = 'Indicate Velocity'
    velocityHeader.paddingLeft = 5
    velocityHeader.color = 'black'
    velocityHeader.fontStyle = 'bold'
    velocityPanel.addControl(velocityHeader)
    ctrlGroup.addControl(velocityPanel);

    const axisPanel = makePanel(5, 283)
    const axisCheckbox = makeCheckbox(false)
    const axisOb = axisCheckbox.onIsCheckedChangedObservable.add(v => setShowAxis(v));
    axisPanel.addControl(axisCheckbox);
    const axisHeader = makeGuiText(null, null, "left")
    axisHeader.text = 'Show Dimensions'
    axisHeader.paddingLeft = 5
    axisHeader.color = 'black'
    axisHeader.fontStyle = 'bold'
    axisPanel.addControl(axisHeader)
    ctrlGroup.addControl(axisPanel);

    const handleResize = () => engine.resize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize);
      simulatorBtn.onPointerUpObservable.remove(startOb)
      pauseBtn.onPointerUpObservable.remove(pauseBtnOb)
      frameSlider.onValueChangedObservable.remove(sliderOb)
      topDownBtn.onPointerUpObservable.remove(topDownBtnOb)
      perspectiveBtn.onPointerUpObservable.remove(perspectiveBtnOb)
      velocityCheckbox.onIsCheckedChangedObservable.remove(velocityOb)
      axisCheckbox.onIsCheckedChangedObservable.remove(axisOb)
    }
  }, [])

  useEffect(() => {
    if (cleanUi) {
      // resetGuiText(inBedText, 30, -20, 90)
      // resetGuiText(inRoomText, 280, -20, 90)
      // resetGuiText(asleepText, 590, -20, 90)
      // resetGuiText(fallenText, 870, -20, 90)
      showPredictionClasses(false)
      algorithmVersionText.isVisible = false
    } else {
      // resetGuiText(inBedText, 30, -20, 60)
      // resetGuiText(inRoomText, 200, -20, 60)
      // resetGuiText(asleepText, 410, -20, 60)
      // resetGuiText(fallenText, 600, -20, 60)
      algorithmVersionText.isVisible = true
    }
    ctrlGroup.isVisible = !cleanUi
  }, [cleanUi])

  useEffect(() => {
    if (theme==='presentation') {
    } else {
    }
  }, [theme])

  useEffect(() => {
    if (!scene || !items) return
    const disposables: B.IDisposable[] = []
    const boardSurfaceCandidates: { mat: B.StandardMaterial, shape: number[] }[] = []

    if (theme === 'presentation') {
      scene.clearColor = B.Color4.FromHexString("#E6E8F211");

      const lightDir = new B.Vector3(0, -2, 0.5)
      const light = new B.HemisphericLight("light3", lightDir, scene);
      light.intensity = .5;
      disposables.push(light)
    } else {
      scene.clearColor = B.Color4.FromHexString("#1E183E11");
    }

    for (const item of items) {
      // update materials
      switch (item.type) {
        case ItemType.Ground:
          if (item.name === "ground") {
            const groundMat = new B.StandardMaterial("groundMat", scene);
            groundMat.diffuseColor = new B.Color3(.7, .7, .7)
            groundMat.specularColor = new B.Color3(.1, .1, .1)
            groundMat.emissiveColor = new B.Color3(.2, .2, .2)
            item.shape.material = groundMat
            disposables.push(groundMat)

            if (theme !== 'presentation') {
              const width = convertMeterToPx(item.pose.shape[0])
              const height = convertMeterToPx(item.pose.shape[1])
              const groundTex = new B.DynamicTexture("WoodenFloor", {width, height}, scene, false)
              groundMat.diffuseTexture = groundTex
              disposables.push(groundTex)
              drawFloor(item)
            }
          }
          break;
        case ItemType.Device:
        {
          const mat = new B.StandardMaterial("deviceMat", scene);
          const width = convertMeterToPx(item.pose.shape[0]) * 2
          const height = convertMeterToPx(item.pose.shape[2]) * 2
          const tex: any = new B.DynamicTexture("deviceMat", {width: width * 2, height}, scene, false)
          mat.diffuseTexture = tex
          mat.emissiveColor = new B.Color3(.55, .55, .55)
          item.shape.material = mat
          drawDevice(item)

          disposables.push(mat)
          disposables.push(tex)

          setShadower(item.shape)
        }
          break;
        case ItemType.Window:
          if (theme === 'presentation') {
            item.shape.visibility = 0
          } else {
            item.shape.visibility = 1
            const winMat = new B.StandardMaterial("winMat", scene);
            const winPic = new B.Texture("/window.png", scene);
            winMat.diffuseTexture = winPic
            winMat.emissiveColor = new B.Color3(1, 1, 1)
            item.shape.material = winMat
            disposables.push(winMat)
            disposables.push(winPic)
            disposables.push(makeWinLight(item, scene))
          }
          break;
        case ItemType.Wall:
          const wallMat = new B.StandardMaterial("wallMat", scene);
          wallMat.specularColor = new B.Color3(.15, .15, .15)
          wallMat.alpha = 1
          item.shape.material = wallMat
          disposables.push(wallMat)

          if (theme !== 'presentation') {
            if (!cleanUi && (item.name === "wall3" || item.name === "wall4")) {
              boardSurfaceCandidates.push({mat: wallMat, shape: item.pose.shape})
            }
            const wallpaper = new B.Texture("/wallpaper.png", scene);
            wallpaper.uScale = item.pose.shape[0] * 10
            wallpaper.vScale = item.pose.shape[1] * 10
            wallMat.diffuseTexture = wallpaper
            disposables.push(wallpaper)
          }
          break;
        case ItemType.Object:
          if (item.pose.shape[0] === 0 || item.pose.shape[1] === 0) {
            item.shape.visibility = 0
          } else {
            const objMat = new B.StandardMaterial("objMat", scene);
            if (theme !== 'presentation') {
              objMat.alpha = 0.8
            }
            item.shape.material = objMat
            disposables.push(objMat)

            if (item.name === 'bed') setShadower(item.shape)
          }
          break;
        case ItemType.Region:
          if (item.pose.shape[0] === 0 || item.pose.shape[1] === 0) {
            item.shape.visibility = 0
          } else {
            const regionMat = new B.StandardMaterial("regionMat", scene);
            regionMat.alpha = 0.1
            item.shape.material = regionMat
            disposables.push(regionMat)
          }
          break;
      }
    }

    const texLen = boardSurfaceCandidates.length
    if (texLen > 0) {
      const candidate = boardSurfaceCandidates.find(c => c.shape[0] > c.shape[1] * 1.5)
      let candidates
      if (candidate != null) candidates = [candidate]
      else if (texLen === 1) candidates = boardSurfaceCandidates
      else candidates = boardSurfaceCandidates.slice(0, 2)

      const textures = []
      for (const c of candidates) {
        const shape = c.shape
        const width = convertMeterToPx(shape[0])
        const height = convertMeterToPx(shape[1])
        const surfaceTex = new B.DynamicTexture("blackboard", {width, height}, scene, false);
        c.mat.diffuseTexture = surfaceTex
        textures.push({tex: surfaceTex, width, height})
      }
      setBoardTex(textures)
    }

    return () => {
      for (let m of disposables) m.dispose()
      setBoardTex(null)
    }
  }, [scene, items, setBoardTex, theme, cleanUi])

  useEffect(() => {
    if (!predictions || predictions['in_bed'] === null) {
      showPredictionClasses(false)
      centroidClouds.updateNextFrame([], false,false)
      vitalClouds.updateNextFrame([], false, false)
      return
    }
    const {in_bed, in_room, asleep, fallen} = predictions
    showPredictionClasses(!cleanUi)
    setPredictionClasses(theme === 'presentation', {in_bed, in_room, asleep, fallen})
    const centroids = _get(predictions, "centroids", [])
    centroidClouds.updateNextFrame(centroids.flat(), true, true)
    const vitalPoint = {...predictions['position'], ...predictions['vitals']}
    vitalClouds.updateNextFrame(vitalPoint.x ? [vitalPoint] : [], true, true)
  }, [predictions])

  useEffect(() => {
    if (framesShow < 1) return
    pointClouds.setup(framesShow)
    arrowClouds.setup(framesShow)
    centroidClouds.setup(framesShow)
    vitalClouds.setup(framesShow)
  }, [framesShow])

  useEffect(() => {
    pointClouds.updateNextFrame(points)
    arrowClouds.updateNextFrame(points, showVelocity)
  }, [points, showVelocity])

  // blackboard data updates
  useEffect(() => board?.setVitals(vitals), [board, vitals])
  useEffect(() => board?.setPoints(points.length), [board, points])
  useEffect(() => {
    if (!board || !timers || !Object.keys(timers).length) return
    board.setTimers(Object.fromEntries(Object.entries(timers).map(
      ([algorithmName, time]) => [algorithmName, time * 1000]
    )))
    const utTimers = timers?.benchmarks?.UserTrackingFn
    if (!utTimers) return
    const meanShift = (utTimers['mean_shift'] - utTimers['start']) * 1000
    const tracking = (utTimers['tracking'] - utTimers['mean_shift']) * 1000
    const output = (utTimers['end'] - utTimers['tracking']) * 1000
    const total = timers['UserTracking'] * 1000
    board.setUserTrackingTimers({meanShift, tracking, output, other: total - meanShift - tracking - output})
  }, [board, timers])
  useEffect(() => {
    board?.updateFrame(fps)
    // eslint-disable-next-line
  }, [board, updateTick])

  const dataTime = _get(timers, 'benchmarks.arrival_time') || _get(timers, 'relayServer.0')
  useEffect(() => {
    if (!dataTime ) return
    dataTimeText.text = "Data Time: " + formatTime(fromUnixTime(dataTime))
    return () => {
      dataTimeText.text = ''
    }
  }, [dataTime])

  useEffect(() => {
    algorithmVersionText.text = fmtVersions(versions?.algorithm || '')
    return () => {
      algorithmVersionText.text = fmtVersions()
    }
  }, [versions?.algorithm])

  useEffect(() => {
    hrText.isVisible = cleanUi && generatedVitals !== null
    brText.isVisible = cleanUi && generatedVitals !== null
    if (!cleanUi || generatedVitals === null) return

    hrText.text = fmtVital('HR', generatedVitals['hr'])
    brText.text = fmtVital('RR', generatedVitals['rr'])
    return () => {
      hrText.text = ''
      brText.text = ''
    }
  }, [generatedVitals, cleanUi])

  useEffect(() => {
    if (!versions?.device) return
    const device = items.find(item => item.type === ItemType.Device)
    if (!device) return
    drawDevice(device, versions.device)
  }, [versions?.device, items])

  useEffect(() => {
    if (theme === 'presentation') return
    if (!Array.isArray(residents) || residents.length <= 0) return
    const floor = items.find(item => item.type === ItemType.Ground)
    if (!floor) return
    const floorMsgs: string[] = [
      RoomType[(_get(residents, '0.room.type') || _get(residents, '0.room_type')) as RoomType],
      _get(residents, '0.room.number') || _get(residents, '0.room_number')
    ]
    drawFloor(floor, floorMsgs.filter(v => v))
  }, [residents, items])

  return <canvas id="renderCanvas"/>
}
