import {Chart} from "chart.js";

const createChart = (chartCanvas: HTMLCanvasElement, title: string | undefined, maxCount: number, color: string,
                     size: { min: number, max: number } | {} = {}) => {
    const datasets = [{
        fill: false,
        data: [],
        borderColor: color,
        tension: 0.1
    }]

    Chart.defaults.font.size = 16
    return new Chart(chartCanvas, {
        type: 'line',
        data: {labels: [...Array(maxCount).keys()], datasets},
        options: {
            elements: {point: {radius: 1}},
            animation: false,
            scales: {
                xAxis: {grid: {display: false}, ticks: {display: false}},
                yAxis: {grid: {color}, ...size},
            },
            plugins: {
                title: {display: title !== undefined, text: title},
                legend: {display: false}
            } as any
        }
    })
}

const createTimeChart = (chartCanvas: HTMLCanvasElement, title: string | undefined, maxCount: number, color: string,
                     size: { min: number, max: number } | {} = {}) => {
    const datasets = [{
        fill: false,
        data: [],
        borderColor: color,
        tension: 0.1
    }]

    Chart.defaults.font.size = 16
    return new Chart(chartCanvas, {
        type: 'line',
        data: {datasets},
        options: {
            elements: {point: {radius: 1}},
            animation: false,
            parsing: {xAxisKey: 'time', yAxisKey: 'value'},
            scales: {
                xAxis: {
                    type: 'time',
                    time: {unit: 'minute', displayFormats: {minute: 'HH:mm', second: 'HH:mm:ss'}} as any,
                    grid: {display: false},
                    ticks: {source: "auto", autoSkip: true} as any,
                },
                yAxis: {grid: {color}, ...size},
            },
            plugins: {
                title: {display: title !== undefined, text: title},
                legend: {display: false}
            } as any
        }
    })
}

export {createChart, createTimeChart}
