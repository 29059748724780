import React, {useEffect} from 'react'
import _get from "lodash-es/get"
import _has from "lodash-es/has"
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from "@mui/material/Button";

import VitalChart from "./VitalChart";
import "./Vitals.css"

interface IVitals {
    vitals: {}
    deviceRates: {}
    dataTime: number
    deviceId: string | undefined
    onVitalsUpdate: any
}

const dataStore: Record<string, any>[] = []

const rrKeyOptions = [
    {name: '10s SMA, corr, filtered', key: 'respiratory.means.10'},
    {name: '20s SMA, corr, filtered', key: 'respiratory.means.20'},
    {name: '30s SMA, corr, filtered', key: 'respiratory.means.30'},
    {name: '10s SMA, corr, unfiltered', key: 'respiratory.unfiltered_means.10'},
    {name: '20s SMA, corr, unfiltered', key: 'respiratory.unfiltered_means.20'},
    {name: '30s SMA, corr, unfiltered', key: 'respiratory.unfiltered_means.30'},
    {name: 'rr', key: 'raw.rr'},
    {name: 'rrcorr', key: 'raw.rrcorr'},
    {name: 'rrfft', key: 'raw.rrfft'},
    {name: 'rrpp', key: 'raw.rrpp'},
]
const rawProcessedRrKeyOptions = [
    {name: '10s SMA, raw processed, filtered', key: 'respiratory.means.10'},
    {name: '20s SMA, raw processed, filtered', key: 'respiratory.means.20'},
    {name: '30s SMA, raw processed, filtered', key: 'respiratory.means.30'},
    {name: 'combined, raw processed, filtered', key: 'respiratory.original'},
    {name: 'bin1, raw processed', key: 'respiratory.full.0.value'},
    {name: 'bin2, raw processed', key: 'respiratory.full.1.value'},
    {name: 'bin3, raw processed', key: 'respiratory.full.2.value'},
    {name: 'bin4, raw processed', key: 'respiratory.full.3.value'},
    {name: 'bin5, raw processed', key: 'respiratory.full.4.value'},
    {name: 'bin6, raw processed', key: 'respiratory.full.5.value'},
    {name: 'bin7, raw processed', key: 'respiratory.full.6.value'},
    {name: 'bin8, raw processed', key: 'respiratory.full.7.value'},
    {name: 'rr', key: 'raw.rr'},
    {name: 'rrcorr', key: 'raw.rrcorr'},
    {name: 'rrfft', key: 'raw.rrfft'},
    {name: 'rrpp', key: 'raw.rrpp'},
]
const hrKeyOptions = [
    {name: '10s SMA, corr, filtered', key: 'heart.means.10'},
    {name: '20s SMA, corr, filtered', key: 'heart.means.20'},
    {name: '30s SMA, corr, filtered', key: 'heart.means.30'},
    {name: '10s SMA, corr, unfiltered', key: 'heart.unfiltered_means.10'},
    {name: '20s SMA, corr, unfiltered', key: 'heart.unfiltered_means.20'},
    {name: '30s SMA, corr, unfiltered', key: 'heart.unfiltered_means.30'},
    {name: 'hr', key: 'raw.hr'},
    {name: 'hrcorr', key: 'raw.hrcorr'},
    {name: 'hrfft', key: 'raw.hrfft'},
    {name: 'hrpp', key: 'raw.hrpp'},
]
const rawProcessedHrKeyOptions = [
    {name: '10s SMA, raw processed, filtered', key: 'heart.means.10'},
    {name: '20s SMA, raw processed, filtered', key: 'heart.means.20'},
    {name: '30s SMA, raw processed, filtered', key: 'heart.means.30'},
    {name: 'combined, raw processed, filtered', key: 'heart.original'},
    {name: 'bin1, raw processed', key: 'heart.full.0.value'},
    {name: 'bin2, raw processed', key: 'heart.full.1.value'},
    {name: 'bin3, raw processed', key: 'heart.full.2.value'},
    {name: 'bin4, raw processed', key: 'heart.full.3.value'},
    {name: 'bin5, raw processed', key: 'heart.full.4.value'},
    {name: 'bin6, raw processed', key: 'heart.full.5.value'},
    {name: 'bin7, raw processed', key: 'heart.full.6.value'},
    {name: 'bin8, raw processed', key: 'heart.full.7.value'},
    {name: 'hr', key: 'raw.hr'},
    {name: 'hrcorr', key: 'raw.hrcorr'},
    {name: 'hrfft', key: 'raw.hrfft'},
    {name: 'hrpp', key: 'raw.hrpp'},
]

function readRates(vitals: {}, rrKey: string, hrKey: string, rawRates: any) {
    return {
        "rr": !rrKey.startsWith('raw') ? _get(vitals, rrKey) : rawRates[rrKey.slice(4)],
        "hr": !hrKey.startsWith('raw') ? _get(vitals, hrKey) : rawRates[hrKey.slice(4)],
        "confidence": _get(vitals, 'respiratory.confidence'),
    }
}

function readLatestRates(deviceId: string, timeSpanSec: number, rrKey: string, hrKey: string): Record<string, any>  {
    const rrRates = []
    const hrRates = []
    const confidence = []
    let latestTime = null, exceedSpanLimit = false
    for (let dataIndex = dataStore.length - 1; dataIndex >= 0; dataIndex--) {
        const curData = dataStore[dataIndex]
        if (curData['deviceId'] !== deviceId) continue
        if (latestTime === null) latestTime = parseISO(curData['time']).getTime()
        if (latestTime - parseISO(curData['time']).getTime() > timeSpanSec * 1000) {
            exceedSpanLimit = true
            break
        }

        const rates = readRates(curData["vitals"], rrKey, hrKey, curData["deviceRates"])
        const timeStr = curData['time']
        rrRates.unshift({'value': rates['rr'], 'time': timeStr})
        hrRates.unshift({'value': rates['hr'], 'time': timeStr})
        confidence.unshift({'value': rates['confidence'], 'time': timeStr})
    }
    if (!exceedSpanLimit && latestTime !== null) {
        const timeStr = formatISO(new Date(latestTime - timeSpanSec * 1000))
        rrRates.unshift({'value': null, 'time': timeStr})
        hrRates.unshift({'value': null, 'time': timeStr})
        confidence.unshift({'value': null, 'time': timeStr})
    }
    return {"rr": rrRates, "hr": hrRates, confidence}
}

const chartTimeSpanSec = 120
const maxStoredCount = chartTimeSpanSec * 5 // TODO: this should be adjustable from UI
const defaultRrKey = rrKeyOptions[1]['key']
const defaultHrKey = hrKeyOptions[1]['key']

const Vitals: React.FC<IVitals> = ({vitals, deviceRates, dataTime, deviceId, onVitalsUpdate}) => {
    const [openChart, setOpenChart] = React.useState(false)
    const [showConfidence, setShowConfidence] = React.useState(false)
    const [rrKey, setRrKey] = React.useState(defaultRrKey)
    const [hrKey, setHrKey] = React.useState(defaultHrKey)

    const [latestChartMagnitudes, setLatestChartMagnitudes] = React.useState<Record<string, any>>([])

    useEffect(() => {
        if (deviceId === undefined || dataTime === 0) return
        const timeFmt = new Date(dataTime * 1000).toISOString()
        dataStore.push({vitals, time: timeFmt, deviceId, deviceRates})
        if (dataStore.length > maxStoredCount) dataStore.shift()

        onVitalsUpdate(readRates(vitals, rrKey, hrKey, deviceRates))
        setLatestChartMagnitudes(readLatestRates(deviceId, chartTimeSpanSec, rrKey, hrKey))
    }, [dataTime, deviceId, rrKey, hrKey])

    const handleDownloadData = () => {
        let dataContent: string = ""
        for (const vitalRow of dataStore) {
            dataContent += JSON.stringify(vitalRow) + "\n"
        }
        download("vitals.csv", dataContent);
    }
    const handleOpenVitalCharts = () => setOpenChart(open => !open)
    const handleShowConfidence = () => setShowConfidence(open => !open)

    const handleRrKeyChange = (event: any) => setRrKey(event.target.value as string)
    const handleHrKeyChange = (event: any) => setHrKey(event.target.value as string)

    const isRawVitalProcessed = _has(vitals, 'respiratory.original')

    useEffect(() => {
        if (!_has(vitals, rrKey)) setRrKey(defaultRrKey)
        if (!_has(vitals, hrKey)) setHrKey(defaultHrKey)
    }, [isRawVitalProcessed]);

    return (
        <Box sx={{zIndex: (theme) => theme.zIndex.drawer + 2}} className={'vitals none-user-select'}>
            <Card variant="outlined" sx={{background: `rgba(255,255,255,${openChart?.7:.15})`}}>
                {openChart ? <CardContent>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Breathing Rate</InputLabel>
                        <Select value={rrKey} onChange={handleRrKeyChange}>
                            {(isRawVitalProcessed ? rawProcessedRrKeyOptions : rrKeyOptions).map((o: any) =>
                              <MenuItem value={o.key} key={o.key}>{o.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <VitalChart color="blue" magnitudes={latestChartMagnitudes['rr']} deviceId={deviceId}/>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Heart Rate</InputLabel>
                        <Select value={hrKey} onChange={handleHrKeyChange}>
                            {(isRawVitalProcessed ? rawProcessedHrKeyOptions : hrKeyOptions).map((o: any) =>
                              <MenuItem value={o.key} key={o.key}>{o.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <VitalChart color="red" magnitudes={latestChartMagnitudes['hr']} deviceId={deviceId}/>
                    {showConfidence && <VitalChart
                        title={'Confidence'} color="red" magnitudes={latestChartMagnitudes['confidence']}
                        deviceId={deviceId} min={0} max={1}
                    />}
                </CardContent> : ''}
                <CardActions>
                    {openChart ? <Button size="small" color="success" onClick={() => handleDownloadData()}>
                        Download ({dataStore.length} rows)
                    </Button> : ''}
                    {openChart? <Button size="small" color="success"
                            onClick={() => handleShowConfidence()}>
                        {showConfidence ? 'Hide Confidence' : 'Show Confidence'}
                    </Button> : ''}
                    <Button size="small" style={{marginLeft: "auto"}} color="warning"
                            onClick={() => handleOpenVitalCharts()}>
                        {openChart ? 'Hide Charts' : 'Vital Processor'}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    )
}
export default Vitals

function download(filename: string, content: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
}
