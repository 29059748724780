import * as B from "babylonjs"
import {Chart, CategoryScale, LinearScale, LineController, PointElement, LineElement, Title, Legend, Filler} from 'chart.js';

Chart.register(CategoryScale, LinearScale, LineController, PointElement, LineElement, Title, Legend, Filler)

function* hueGenerator() {
  let hs = [1]
  let nextH = 0
  while (true) {
    yield nextH * 360
    hs.push(nextH)
    hs.sort((a, b) => a - b)
    const gaps = []
    for (let i = 1; i < hs.length; i++) gaps.push(hs[i] - hs[i - 1])
    const maxGap = Math.max.apply(null, gaps)
    const indexMax = gaps.indexOf(maxGap)
    nextH = (hs[indexMax] + hs[indexMax + 1]) / 2
  }
}

export class LineChart implements B.IDisposable {
  private readonly canvas: HTMLCanvasElement
  private readonly chart: Chart
  private readonly count = 50

  constructor(private title: string,
              width: number = 200,
              height: number = 100,
              dataLabels: string[],
              isStacked: boolean = false,
              canvas: HTMLCanvasElement | null = null,
              ) {
    if (canvas !== null) {
      this.canvas = canvas
    } else {
      this.canvas = document.createElement('canvas')
      this.canvas.height = height
      this.canvas.width = width
      this.canvas.style.backgroundColor = 'white'
    }

    const hueGen = hueGenerator()
    const labelHues: Record<string, any> = dataLabels.reduce((hues, l) => ({...hues, [l]: hueGen.next().value}), {})
    const datasets = dataLabels.map(l => ({
      label: l,
      fill: isStacked ? 'stack' : false,
      data: Array(this.count),
      borderColor: `hsl(${labelHues[l]},100%,50%)`,
      backgroundColor: `hsla(${labelHues[l]},100%,50%,.8)`,
      yAxisID: isStacked ? 'Y' : l + 'Y'
    }))
    const yScaleDefaults = {axis: 'y', stacked: true, suggestedMin: 0, suggestedMax: 6, grid: {display: false}}
    const yScales = isStacked
      ? {'Y': yScaleDefaults}
      : Object.fromEntries(dataLabels.map((l, i) => [l + 'Y', {
        ...yScaleDefaults,
        ...(i > 0 && {position: 'right'}),
        ticks: {color: `hsl(${labelHues[l]}, 100%, 50%)`}
      }]))
    Chart.defaults.font.size = 16
    this.chart = new Chart(this.canvas.getContext('2d') as any, {
      type: 'line',
      data: {labels: [...Array(this.count).keys()], datasets},
      options: {
        fill: false,
        spanGaps: true,
        scales: {
          xAxis: {grid: {display: false, drawBorder: true, borderColor: 'white'}, ticks: {display: false}},
          ...yScales
        },
        responsive: false,
        animation: false,
        elements: {point: {radius: 0}},
        plugins: {
          title: {display: true, color: 'white', text: this.title}
        }
      } as any,
    })
  }

  add(data: Record<string, any>) {
    this.chart.data.datasets.forEach((dataset) => {
      if (!dataset.label || !(dataset.label in data)) return
      dataset.data.push(data[dataset.label]);
      if (dataset.data.length > this.count) dataset.data.shift()
    })
    try {
      this.chart.update();
    } catch (e) {
    }
  }

  getCanvas(): HTMLCanvasElement {
    return this.canvas
  }

  dispose() {
    this.chart.destroy()
    this.canvas.remove()
  }
}