import packageJson from '../../package.json'

export const isEmptyObject = (o: {}) => Object.keys(o).length === 0 && o.constructor === Object

export const fmtVersions = (algorithmVersion: string = '') => {
    return `Versions: App ${packageJson.version}` + (algorithmVersion.length > 0 ? `, Algorithm: ${algorithmVersion}` : '')
}

export const breathColor = '#1E3FD6'
export const heartColor = '#FF5A5D'

export const fmtVital = (vitalName: string, value: number) => {
    return `${vitalName} ${(value ? `${Math.round(value)}` : "--").padStart(3, ' ')}`
}
