import {DeviceBehaviorsImpl} from "../core/infrastructure/DeviceBehaviorsImpl";
import * as B from "babylonjs";
import {Item, ItemType} from "../core/entities/Item";

const deviceBehavior = new DeviceBehaviorsImpl()
const options: any = {
  points: [] as B.Vector3[],
  updatable: true
}
const rayColor1 = new B.Color4(0.8, 0.8, 1.0, 0.8)
const rayColor0 = new B.Color4(0.8, 0.8, 1.0, 0.5)

const lines: B.LinesMesh[] = []
const points: B.Mesh[] = []

const updateItems = (items: Item[]) => {
  const rayTargets = items
    .filter(item => ![ItemType.Device, ItemType.Person, ItemType.Region].includes(item.type))
    .map(item => item.shape)

  for (const item of items) {
    if (item.type === ItemType.Device) {
      const rayData = deviceBehavior.GetNextRay(item)
      const rayEnd = B.Vector3.FromArray(rayData).scale(5)
      const rayStart = item.shape.position
      const ray = new B.Ray(rayStart, rayEnd, 5);
      const hitInfo = ray.intersectsMeshes(rayTargets);

      if (hitInfo.length) {
        rayEnd.copyFrom(hitInfo[0].pickedPoint as B.Vector3)
        const hitPointOp = {diameter: 0.02, segments: 2}
        const hitPoint = B.MeshBuilder.CreateSphere("hitPoint", hitPointOp);
        hitPoint.position.copyFrom(rayEnd)
        points.push(hitPoint)
      }
      options.points = [rayStart, rayEnd]
      options.colors = [rayColor1, rayColor0]
      lines.push(B.MeshBuilder.CreateLines("lines", options));
    }
  }
}

let rayTickId: NodeJS.Timeout
export const startAnimation = (items: Item[]) => {
  rayTickId = setInterval(() => updateItems(items), 1)
}

export const stopAnimation = () => clearInterval(rayTickId)
export const clearAnimation = () => {
  for (const l of lines) l.dispose()
  for (const p of points) p.dispose()
  lines.length = 0
  points.length = 0
}

// Assuming up is [0,1,0]
export function dir2RotVec3(dir: B.Vector3) {
  const forwardVec3 = B.Vector3.Forward()
  const dirVec3 = B.Vector3.Zero().copyFrom(dir).normalize()
  const dirXZVec3 = new B.Vector3(dirVec3.x, 0, dirVec3.z).normalize()

  const cy = Math.asin(B.Vector3.Cross(forwardVec3, dirXZVec3).y)
  const dy = Math.acos(B.Vector3.Dot(forwardVec3, dirXZVec3))
  const rotY = cy < 0 ? cy : dy
  const cx = Math.asin(B.Vector3.Cross(dirXZVec3, dirVec3).z)
  const dx = Math.acos(B.Vector3.Dot(dirXZVec3, dirVec3))
  const rotX = cx < 0 ? cx : dx
  return new B.Vector3(rotX, rotY, 0)
}
