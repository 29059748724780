import React, {useState} from 'react'
import {Chart} from 'chart.js'
import {createChart} from '../services/chart.service'

interface IWaveformChart {
    title: string
    magnitudes: number[]
    deviceId: string | undefined
    color: string
}

const maxCount = 300

const WaveformChart: React.FC<IWaveformChart> = ({title, magnitudes, deviceId, color}) => {
    const chartBoard = React.useRef<HTMLCanvasElement | null>(null);
    const [chart, setChart] = React.useState<Chart | null>(null);
    const [waveformQueue, setWaveformQueue] = useState<number[]>([])
    const [chartWaveform, setChartWaveform] = useState<number[]>([])
    const [updateTick, setUpdateTick] = useState<number>(0)

    React.useEffect(() => {
        if (title == null) return
        const c = createChart(chartBoard.current as HTMLCanvasElement, title, maxCount, color, {min: -1, max: 1})
        setChart(c)
        setWaveformQueue([])
        setChartWaveform([...Array(maxCount)])

        return () => {
            c.destroy()
            setChart(null)
        }
    }, [title, deviceId, color])

    React.useEffect(() => {
        if (Array.isArray(magnitudes))
            setWaveformQueue(rwfQueue => [...rwfQueue, ...magnitudes])
    }, [magnitudes])

    React.useEffect(() => {
        if (!chart) return
        chart.data.datasets[0].data = chartWaveform
        chart.canvas && chart.update()
    }, [chart, chartWaveform])

    React.useEffect(() => {
        if (waveformQueue.length === 0) return
        let newElement = waveformQueue.shift() as number
        setChartWaveform(chartWaveform => [...chartWaveform.slice(1), newElement])
        setWaveformQueue(waveformQueue)
        // eslint-disable-next-line
    }, [updateTick])

    React.useEffect(() => {
        const intId = setInterval(() => {
            setUpdateTick(tick => (tick + 1 % 1000))
        }, 1000 / 21)
        return () => clearInterval(intId)
    }, [])

    return <>
        <canvas ref={chartBoard} width="400" height="100"/>
    </>
}
export default WaveformChart
