import React, {useCallback, useEffect, useState} from "react";
import _get from "lodash-es/get";
import "./App.css"
import {useNavigate, useParams} from "react-router-dom"

import {Visualizer} from "./app/Visualizer";
import {useWebsocket} from "./app/websocket.hook";
import {isEmptyObject} from "./app/utils";
import {useRoomItems} from "./app/roomItems.hook";
import {DEFAULT_DEVICE_META, DeviceMetaDTO} from "./core/infrastructure/dtos/DeviceMetaDTO";
import {useServerConfig} from "./app/server-config.hook";
import InfoCard from "./app/InfoCard";
import Vitals from "./app/components/Vitals";
import {useFpsTick} from "./app/fps-tick.hook";
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from '@mui/material/Typography'
import {
  DeviceData,
  findSourceByName,
  isSourceOnline,
  defaultSourceName,
  Source,
  SourceDTO, onlineSourceNames
} from "./app/device-data.service";
import PlayerPanel from "./app/PlayerPanel";
import {DimensionDTO} from "./core/infrastructure/dtos/DimensionDTO";

import * as DeviceDataPb from "./protos/device_data_pb"
import fromUnixTime from "date-fns/fromUnixTime";
import {hotkeys, useKeyDownEvent} from "./app/useHotkey.hook";
import Logo from "./app/logo"
import {frameQ} from "./app/services/frameQueue.service";

const FPS_MEASURE_PERIOD = 5000
const getDeviceMetaCacheId = (id: number) => `deviceMeta.${id}`

enum Status {Loading, Offline, Online}

interface IApp {
  username: string,
}

const App: React.FC<IApp> = ({username}) => {
  const {deviceId, sourceName} = useParams();
  const navigate = useNavigate();

  const [theme, setTheme] = useState<string>('original')
  const [isHideUi, setIsHideUi] = useState<boolean>(false)

  const [keydownEvent] = useKeyDownEvent()
  const [wsHost] = useServerConfig()
  const [frameData, setFrameData] = useState<DeviceData>()
  const [roomDimension, setRoomDimension] = useState<DimensionDTO>()
  const [predictions, setPredictions] = useState<Record<string, any>>({})
  const [versions, setVersions] = useState<Record<string, any>>({})
  const [timers, setTimers] = useState<Record<string, any>>({})
  const [deviceMeta, setDeviceMeta] = useState<DeviceMetaDTO>(DEFAULT_DEVICE_META)
  const [items] = useRoomItems(deviceMeta)
  const [source, setSource] = useState<Source | null>()
  const [sourceList, setSourceList] = useState<SourceDTO[]>([])
  const [deviceList, setDeviceList] = useState<Record<number, DeviceMetaDTO[]>>({})
  const [onlineDeviceList, setOnlineDeviceList] = useState<Record<number, DeviceMetaDTO[]>>({})
  const [sourceDeviceList, setSourceDeviceList] = useState<Record<number, DeviceMetaDTO[]>>({})
  const [onlineDeviceConnections, setOnlineDeviceConnections] = useState<Record<number, number>>({})
  const [ws, wsPayload, wsMessage] = useWebsocket(`${wsHost}:8088/ws`, username)
  const [pause, setPause] = useState<boolean>(false)
  const [dataTimes, setDataDataTimes] = useState<number[]>([])
  const [generatedVitals, setGeneratedVitals] = useState<any>({})
  const [fps, setFps] = useState<number>(0)
  const [fpsTick] = useFpsTick()
  const [openSnack, setOpenSnack] = useState<boolean>(false)
  const [snackMsg, setSnackMsg] = useState<string>('')
  const [status, setStatus] = useState(Status.Loading)

  useEffect(() => {
    if (!keydownEvent) return
    if (hotkeys['ctrl+p'].equal(keydownEvent)) {
      setTheme('presentation')
    } else if (hotkeys['ctrl+o'].equal(keydownEvent)){
      setTheme('original')
    } else if (hotkeys['ctrl+h'].equal(keydownEvent)){
      setIsHideUi(isHideUi=> !isHideUi)
    } else if (hotkeys['ctrl+1'].equal(keydownEvent)){
      updateNavigation(onlineSourceNames[1], deviceId)
    } else if (hotkeys['ctrl+2'].equal(keydownEvent)) {
      updateNavigation(onlineSourceNames[0], deviceId)
    }
  }, [keydownEvent]);

  useEffect(() => {
    const dim = DimensionDTO.fromDict(deviceMeta.room_dimension as any)
    if (!dim) return
    setRoomDimension(dim)

    let problematicAxes = []
    if (dim.x_max - dim.x_min <= 0.1) problematicAxes.push(`x (max:${dim.x_max}, min:${dim.x_min})`)
    if (dim.y_max - dim.y_min <= 0.1) problematicAxes.push(`y (max:${dim.y_max}, min:${dim.y_min})`)
    if (dim.z_max - dim.z_min <= 0.1) problematicAxes.push(`z (max:${dim.z_max}, min:${dim.z_min})`)

    let msg = ''
    if (problematicAxes.length > 0) {
      msg = `There is something wrong with the room dimensions: ${problematicAxes.join(', ')}`
    }
    if (deviceMeta.id < 0) {
      msg = 'Device is not set up properly. Default values are used'
    }
    setSnackMsg(msg)
    setOpenSnack(msg.length > 0)
  }, [deviceMeta])

  useEffect(() => {
    setFrameData(undefined)
    setPredictions({})
    setTimers({})
    setDataDataTimes([])
    setStatus(Status.Loading)
    frameQ.purge()
  }, [deviceId])

  useEffect(() => {
    const dto = findSourceByName(sourceList, sourceName)
    if (!dto) return
    setSource(new Source(dto))
  }, [sourceName, sourceList])

  useEffect(() => {
    setDeviceList(isSourceOnline(sourceName) ? onlineDeviceList : sourceDeviceList)
  }, [sourceName, onlineDeviceList, sourceDeviceList])

  useEffect(() => {
    if (deviceId === undefined) return
    let devMeta: DeviceMetaDTO | null = null
    if (deviceList[+deviceId]) {
      devMeta = deviceList[+deviceId][0]
    } else {
      const devMetaJson = localStorage.getItem(getDeviceMetaCacheId(+deviceId))
      if (devMetaJson !== null) try {
        devMeta = JSON.parse(devMetaJson)[0]
      } catch {
      }
    }
    setDeviceMeta((devMeta && (devMeta.room_id > 0 || devMeta.id > 0)) ? devMeta : DEFAULT_DEVICE_META)
  }, [deviceId, deviceList])

  const requestSourceChange = useCallback((src: string) => ws?.send(`source:${src}`), [ws])

  useEffect(() => {
    const src = sourceName || defaultSourceName
    requestSourceChange(src)
    const firstBeat = setTimeout(() => requestSourceChange(src), 2000)
    const subscribeHeartBeat = setInterval(() => requestSourceChange(src), 19000)
    return () => {
      clearTimeout(firstBeat)
      clearInterval(subscribeHeartBeat)
    }
  }, [requestSourceChange, sourceName])

  const requestSubscription = useCallback((id: string) => ws?.send(`id:${+id}`), [ws])

  useEffect(() => {
    if (deviceId === undefined) return
    requestSubscription(deviceId)
    const firstBeat = setTimeout(() => requestSubscription(deviceId), 2000)
    const subscribeHeartBeat = setInterval(() => requestSubscription(deviceId), 19000)
    return () => {
      clearTimeout(firstBeat)
      clearInterval(subscribeHeartBeat)
    }
  }, [requestSubscription, deviceId, sourceName])

  useEffect(() => {
    if (isEmptyObject(wsPayload)) return
    const payloadKeys = Object.keys(wsPayload)
    switch (payloadKeys[0]) {
      case "Runs":
        setSourceList(wsPayload["Runs"])
        break
      case "source":
      case "source_devices":
        setSourceDeviceList(wsPayload['source_devices'])
        break
      case "Devices":
        const devices = wsPayload["Devices"] as Record<number, DeviceMetaDTO[]> || {}
        for (const [deviceId, residents] of Object.entries(devices)) {
          localStorage.setItem(getDeviceMetaCacheId(+deviceId), JSON.stringify(residents))
        }
        setOnlineDeviceList(devices)
        break
      case "deviceConnections":
        setOnlineDeviceConnections(wsPayload["deviceConnections"])
        break
      case "id":
      case "residents":
        const {id: deviceId} = wsPayload
        if (deviceId < 0) return setStatus(Status.Offline)
        setStatus(Status.Online)
        break
      case "pause":
        wsPayload["pause"] === username && setPause(true)
        break
      case "unpause":
        wsPayload["unpause"] === username && setPause(false)
        break
      default:
        if (!pause) {
          frameQ.pushFrame(wsPayload)
        }
    }
  }, [username, wsPayload, pause])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (frameQ.isReady()) {
        const data = frameQ.popFrame() as any;
        if (!data["device_data_pb"]) {
          console.error("Wrong data received:", data)
          return
        }
        const pbData = DeviceDataPb.DeviceData.deserializeBinary(data["device_data_pb"]);
        const deviceData = new DeviceData(pbData)
        setFrameData(deviceData)
        setPredictions(data["predictions"])
        setTimers(data["timers"])
        setVersions(data['versions'])
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    if (pause && frameData?.pointCloud.length) console.log("Cloud:", frameData.pointCloud, "Predictions:", predictions)
  }, [pause, frameData, predictions])

  const updateNavigation = useCallback(
    (sourceToBe: string | undefined, deviceIdToBe: string | undefined) => {
      const deviceParam = deviceIdToBe ? `/device/${+deviceIdToBe}` : ""
      navigate(`/source/${sourceToBe || defaultSourceName}` + deviceParam)
    }, [navigate])

  const handleSourceChange = useCallback((event: any, sourceToBe: string) => {
    if (sourceName !== sourceToBe) updateNavigation(sourceToBe, deviceId)
  }, [sourceName, deviceId, updateNavigation])

  const handleDeviceChange = useCallback((event: any, deviceIdToBe: string) => {
    if (deviceId !== deviceIdToBe) updateNavigation(sourceName, deviceIdToBe)
  }, [sourceName, deviceId, updateNavigation])

  const handlePause = useCallback((isPausing: boolean) => {
    ws?.send(`${isPausing ? "pause" : "unpause"}`)
  }, [ws])

  useEffect(() => setDataDataTimes(times => {
    const now = performance.now()
    while (times.length > 0 && times[0] < now - FPS_MEASURE_PERIOD) times.shift()
    return [...times, performance.now()]
  }), [frameData])

  useEffect(() => {
    const c = dataTimes.length
    setFps(c <= 5 ? 0 : c * 1000 / (performance.now() - dataTimes[0]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fpsTick])

  const getDeviceVitals = () => _get(frameData?.vitals, "0", {})

  const handleTimeSelection = (atSecond: number) => {
    ws?.send(`goto-second:${atSecond}`)
    frameQ.purge()
  }
  return (
    <div className="App fill-screen">
      {isHideUi? <Logo />: ''}
      <Vitals
          vitals={predictions?.vitals || {}}
          dataTime={frameData?.recordedAt || 0}
          deviceId={deviceId}
          deviceRates={getDeviceVitals()}
          onVitalsUpdate={(rates: any) => setGeneratedVitals(rates)}
      />
      <InfoCard
        theme={theme}
        minimized={isHideUi}
        username={username}
        resident={deviceMeta}
        timers={timers}
        dataTime={frameData?.recordedAt || 0}
        fps={fps}
        rawVitals={getDeviceVitals()}
        generatedVitals={generatedVitals}
        points={frameData?.pointCloud.length || 0}
        sourceName={sourceName||defaultSourceName}
        sources={sourceList}
        onSourceChange={handleSourceChange}
        deviceId={deviceId}
        devices={deviceList}
        deviceConnections={onlineDeviceConnections}
        onDeviceChange={handleDeviceChange}
      />
      <Visualizer
        theme={theme}
        cleanUi={isHideUi}
        points={frameData?.pointCloud || []}
        roomDimensionPayload={roomDimension?.serialize()}
        predictions={predictions}
        timers={timers}
        versions={versions}
        items={items}
        residents={deviceList[+(deviceId || 0)]}
        fps={fps}
        updateTick={fpsTick}
        vitals={getDeviceVitals()}
        generatedVitals={sourceName !== defaultSourceName ? generatedVitals : null}
        wsMessage={wsMessage}
        pause={pause}
        onPause={handlePause}
      />
      {!source || isSourceOnline(sourceName) || !deviceId ? ""
        : <PlayerPanel source={source}
                       currentTime={frameData?.recordedAt ? fromUnixTime(frameData?.recordedAt) : null}
                       onTimeSelection={handleTimeSelection}/>
      }
      <Snackbar open={openSnack} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert severity="warning" sx={{width: '100%'}}>{snackMsg}</Alert>
      </Snackbar>
      <Backdrop sx={{
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,.3)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        pointerEvents: 'none'
      }} open={status !== Status.Online}>
        {status===Status.Loading && <CircularProgress color="inherit" />}
        {status===Status.Offline && <Typography variant="h2" gutterBottom component="div">
          Device Unavailable
        </Typography>}
      </Backdrop>
    </div>
  )
}

export default App;