import * as B from "babylonjs";
import * as GUI from "babylonjs-gui";
import {Item} from "../core/entities/Item";
import {Axis} from "./elements/Axis";

let axis: Axis | null
export const CAMERA_TARGET_0 = new B.Vector3(0, 0, 1.0)

export function updateAxes(minX: number, minY: number, minZ: number, maxX: number, maxY: number, maxZ: number) {
  axis?.rebuildCoords(new B.Vector3(minX, minY, minZ), new B.Vector3(maxX, maxY, maxZ))
}

export function setAxisVisibility(isVisible: boolean) {
  axis?.setVisibility(isVisible)
}

let shadowGenerator :B.ShadowGenerator

export function setShadower(s: B.Mesh) {
  const shadowerList = shadowGenerator.getShadowMap()?.renderList
  if (!shadowerList) return
  const idx = shadowerList.findIndex(shadower => shadower.name === s.name)
  if (idx !== -1) shadowerList.splice(idx, 1)
  shadowerList.push(s)
}

export function createScene(canvas: HTMLCanvasElement, engine: B.Engine) {
  const scene = new B.Scene(engine)

  const camera = new B.ArcRotateCamera("camera1", 0, 0, 6, CAMERA_TARGET_0.clone(), scene)
  camera.lowerRadiusLimit = 1
  camera.minZ = 0.001
  camera.wheelPrecision = 100;
  camera.inputs.remove(camera.inputs.attached.keyboard);
  camera.inputs.add(new FreeCameraKeyboardWalkInput());
  camera.attachControl(canvas, true);

  const lightDir = new B.Vector3(2, 1, 2)
  const light = new B.HemisphericLight("light1", lightDir, scene);
  light.intensity = .6;

  const lightDir2 = new B.Vector3(-2, 1, -2)
  const light2 = new B.HemisphericLight("light2", lightDir2, scene);
  light2.intensity = .6;

  axis = new Axis(0.05, scene)

  const lightDir3 = new B.Vector3(-.5, -3, -1.)
  const shadowLight  = new B.DirectionalLight("dir01", lightDir3, scene)
  shadowLight.position = lightDir3.scale(-1)
  shadowLight.intensity = .2
  shadowGenerator = new B.ShadowGenerator(256, shadowLight);
  shadowGenerator.useBlurExponentialShadowMap = true
  shadowGenerator.filteringQuality = B.ShadowGenerator.QUALITY_LOW;
  return scene
}

export function makeWinLight(winItem: Item, s: B.Scene): B.Light {
  const pos = B.Vector3.FromArray(winItem.pose.position)
  const dir = B.Vector3.FromArray(winItem.pose.forward).scale(-1)
  const name = winItem.name + "Light"
  const angle = Math.PI / 6 * 5
  const light = new B.SpotLight(name, pos, dir, angle, 2, s);
  light.intensity = 0.9
  return light
}

export function createGui(name: string) {
  return GUI.AdvancedDynamicTexture.CreateFullscreenUI(name);
}

export function makePanel(x: number, y: number): GUI.StackPanel {
  const panel = new GUI.StackPanel();
  panel.width = "200px";
  panel.isVertical = false;
  panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  panel.top = y + "px"
  panel.left = x + "px"
  return panel
}

export function makeGuiSlider(x: number, y: number): GUI.Slider {
  const slider = new GUI.Slider()
  slider.minimum = 1
  slider.maximum = 10
  slider.value = 3
  slider.height = '20px'
  slider.width = '150px'
  slider.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  slider.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  slider.top = y + "px"
  slider.left = x + "px"
  slider.step = 1
  return slider
}

export function makeCheckbox(isChecked: boolean = true): GUI.Checkbox {
  const checkbox = new GUI.Checkbox();
  checkbox.width = "20px"
  checkbox.height = "20px";
  checkbox.color = "white"
  checkbox.isChecked = isChecked;
  checkbox.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  checkbox.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  return checkbox
}

export function makeGuiButton(name: string, x: number, y: number, size: number = 40): GUI.Button {
  const btn = GUI.Button.CreateSimpleButton("btn" + name, name);
  btn.width = "150px"
  btn.height = size + "px";
  btn.fontSize = size / 2 - 2
  btn.color = "white";
  btn.cornerRadius = 5;
  btn.background = "grey";
  btn.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  btn.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  btn.top = y + "px";
  btn.left = x + "px";
  return btn
}

export function makeGuiText(x: number | null, y: number | null, align: string = ""): GUI.TextBlock {
  const text = new GUI.TextBlock();
  text.width = "150px"
  text.height = "40px";
  text.color = "white";
  text.fontSize = 16;
  text.horizontalAlignment = (align.toLowerCase() === 'left')
    ? GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
    : GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  text.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  if (y !== null) text.top = y + "px";
  if (x !== null) text.left = x + "px";
  text.resizeToFit = true
  return text
}

class FreeCameraKeyboardWalkInput implements B.ICameraInput<B.ArcRotateCamera> {
  private _keys: string[] = [];
  private readonly moveSpeedFactor = 0.01
  private readonly keysForward = ['ArrowUp', 'KeyW'];
  private readonly keysBackward = ['ArrowDown', 'KeyS'];
  private readonly keysLeft = ['ArrowLeft', 'KeyA'];
  private readonly keysRight = ['ArrowRight', 'KeyD'];
  private readonly keysUp = ["PageUp", "Space"];
  private readonly keysDown = ["PageDown", 'KeyX'];

  // @ts-ignore
  public readonly camera: B.ArcRotateCamera
  private noPreventDefault: boolean = false

  private _onKeyDown = (evt: KeyboardEvent) => {
    if (this._isKeyRegistered(evt.code)) {
      if (!this._keys.includes(evt.code)) this._keys.push(evt.code);
      if (!this.noPreventDefault) evt.preventDefault();
    }
  };

  private _onKeyUp = (evt: KeyboardEvent) => {
    if (this._isKeyRegistered(evt.code)) {
      const index = this._keys.indexOf(evt.code);
      if (index >= 0) this._keys.splice(index, 1);
      if (!this.noPreventDefault) evt.preventDefault();
    }
  };

  //Add attachment controls
  attachControl(noPreventDefault: boolean) {
    const ele = this.camera.getEngine().getInputElement() as HTMLElement;
    ele.tabIndex = 1;
    ele.addEventListener("keydown", this._onKeyDown, false);
    ele.addEventListener("keyup", this._onKeyUp, false);
    ele.focus()
  };

  //Add detachment controls
  detachControl() {
    const ele = this.camera.getEngine().getInputElement() as HTMLElement;
    ele.removeEventListener("keydown", this._onKeyDown);
    ele.removeEventListener("keyup", this._onKeyUp);
    B.Tools.UnregisterTopRootEvents(window, [{name: "blur", handler: () => this._keys = []}]);
    this._keys = [];
  };

  //Keys movement control by checking inputs
  checkInputs() {
    const c = this.camera;
    for (let i = 0; i < this._keys.length; i++) {
      const keyCode = this._keys[i];
      const v = c.speed
      const shift = new B.Vector3()
      if (this.keysLeft.includes(keyCode)) {
        shift.copyFromFloats(v * -this.moveSpeedFactor, 0, 0)
      } else if (this.keysForward.includes(keyCode)) {
        shift.copyFromFloats(0, 0, v * this.moveSpeedFactor)
      } else if (this.keysRight.includes(keyCode)) {
        shift.copyFromFloats(v * this.moveSpeedFactor, 0, 0)
      } else if (this.keysBackward.includes(keyCode)) {
        shift.copyFromFloats(0, 0, v * -this.moveSpeedFactor)
      } else if (this.keysUp.includes(keyCode)) {
        shift.copyFromFloats(0, v * this.moveSpeedFactor, 0)
      } else if (this.keysDown.includes(keyCode)) {
        shift.copyFromFloats(0, v * -this.moveSpeedFactor, 0)
      }
      if (c.getScene().useRightHandedSystem) {
        c.cameraDirection.z *= -1;
      }
      const inv = c.getViewMatrix().invert();
      const transformedShift = new B.Vector3()
      B.Vector3.TransformNormalToRef(shift, inv, transformedShift);
      c.target.addInPlace(transformedShift);
    }
  };

  getClassName() {
    return "FreeCameraKeyboardWalkInput";
  };

  getSimpleName() {
    return "keyboard";
  };

  private _isKeyRegistered(code: string) {
    return this.keysForward.includes(code) ||
      this.keysBackward.includes(code) ||
      this.keysLeft.includes(code) ||
      this.keysRight.includes(code) ||
      this.keysUp.includes(code) ||
      this.keysDown.includes(code)
  }
}

export function addGuiText(text: string, x: number, y: number, fontsize: number = 16) {
  const t = new GUI.TextBlock();
  t.text = text;
  t.fontSize = fontsize;
  t.fontFamily = "impact"
  t.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  t.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM
  t.top = y + "px";
  t.left = x + "px";
  t.alpha = 0.7
  return t
}

export function resetGuiText(t: GUI.TextBlock, x: number, y: number, fontSize: number = 16) {
  t.top = y + "px";
  t.left = x + "px";
  t.fontSize = fontSize
}

const MAT_RESOLUTION = 512

export const convertMeterToPx = (meter: number) => MAT_RESOLUTION * meter

export function drawFloor(floor: Item, floorMessages: string[] = []) {
  const tex = floor.shape.material.diffuseTexture
  const width = tex._texture.width
  const height = tex._texture.height
  const ctx = tex.getContext()
  const img = new Image();
  img.src = "/wood.jpg";
  img.onload = () => {
    const imgSize = 1024
    for (let w = 0; w < width; w += imgSize) {
      for (let h = 0; h < height; h += imgSize) {
        ctx.drawImage(img, w, h, imgSize, imgSize)
      }
    }
    let y = 70
    for (const msg of floorMessages) {
      tex.drawText(msg, 10, y, "64px impact", "white", null, true, true);
      y += 70
    }
    tex.update()
  }
  tex.onDispose = () => img.onload = null
}

export function drawDevice(device: Item, backMessages: string = "") {
  const tex = device.shape.material.diffuseTexture
  const width = tex._texture.width
  const height = tex._texture.height
  tex.drawText("Tellus", width / 2 / 4, height / 2 + 5, "20px impact", "white", "lightgrey", true, true);
  tex.drawText(backMessages, width / 2 * 1.25 + 10, height / 2 + 10, "40px impact", "white", null, true, true);
  tex.update()
}
