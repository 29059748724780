import _has from "lodash-es/has"
import _get from "lodash-es/get"
import _set from "lodash-es/set"
import _unset from "lodash-es/unset"
import * as GUI from "babylonjs-gui"
import * as B from "babylonjs"

const labelCache: Record<string, CachedLabelGroup> = {}

const getLabelGroupKey = (groupName: string) => `managed:${groupName}`

export function RegisterLabel(mesh: B.InstancedMesh, groupName: string, i: number, labelText: string, labelDecor = true, width = 135) {
  const key = getLabelGroupKey(groupName)
  if (!_has(labelCache, key)) {
    _set(labelCache, key, CreateLabelGroup(groupName))
  }
  const labelGroup = _get(labelCache, key)
  labelGroup.addLabelToMesh(i, mesh, labelText, labelDecor, width)
}

export function DeRegisterLabel(groupName: string) {
  const key = getLabelGroupKey(groupName)
  _get(labelCache, key)?.purge([])
  _unset(labelCache, key)
}

export function CreateLabelGroup(name: string) {
  return new CachedLabelGroup(GUI.AdvancedDynamicTexture.CreateFullscreenUI(name))
}

export class CachedLabelGroup {
  private _cachedElementGroup: Record<number, Record<string, B.IDisposable>> = {}

  constructor(private _gui: GUI.AdvancedDynamicTexture) {
  }

  private getLabelFrame(i: number, labelDecor: boolean, width: number) {
    const key = `${i}.labelFrame`
    if (!_get(this._cachedElementGroup, key)) {
      const frame = new GUI.Rectangle();
      frame.width = `${width}px`;
      frame.height = "13px";
      frame.cornerRadius = 5;
      frame.color = "Orange";
      frame.thickness = labelDecor ? 0.5 : 0;
      frame.alpha = 0.8;
      if (labelDecor) {
        frame.background = "darkgreen";
        frame.linkOffsetY = -25;
        frame.linkOffsetX = 40 + width / 2;
      }
      this._gui?.addControl(frame);
      _set(this._cachedElementGroup, key, frame)
    }
    return _get(this._cachedElementGroup, key) as GUI.Rectangle
  }

  private getLabelTextBlock(i: number) {
    const key = `${i}.labelText`
    if (!_get(this._cachedElementGroup, key)) {
      const textBlock = new GUI.TextBlock();
      textBlock.fontSize = 10;
      _set(this._cachedElementGroup, key, textBlock)
    }
    return _get(this._cachedElementGroup, key) as GUI.TextBlock
  }

  private getLabelLine(i: number, width: number) {
    const key = `${i}.labelLine`
    if (!_get(this._cachedElementGroup, key)) {
      const line = new GUI.Line();
      line.lineWidth = 0.5;
      line.color = "Orange";
      line.alpha = 0.5;
      line.y2 = 8;
      line.x2 = -width/2;
      this._gui?.addControl(line);
      _set(this._cachedElementGroup, key, line)
    }
    return _get(this._cachedElementGroup, key) as GUI.Line
  }

  public addLabelToMesh(i: number, m: B.InstancedMesh, text: string, labelDecor = true, width = 135) {
    const frame = this.getLabelFrame(i, labelDecor, width)
    frame.linkWithMesh(m);

    const textBlock = this.getLabelTextBlock(i)
    textBlock.text = text;
    frame.addControl(textBlock);

    if (labelDecor) {
      const line = this.getLabelLine(i, width);
      line.linkWithMesh(m);
      line.connectedControl = frame;
    }
  }

  public purge(retainedKeys: number[]) {
    for (const key of Object.keys(this._cachedElementGroup)) {
      if (!retainedKeys.includes(+key)) {
        for (const element of Object.values(this._cachedElementGroup[+key])) element.dispose()
        delete this._cachedElementGroup[+key]
      }
    }
  }
}
