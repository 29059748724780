import * as B from "babylonjs";
import * as GUI from "babylonjs-gui";
import {createGui} from "../scene.service";

export class Axis {
  private readonly _meshes: B.Mesh[] = []
  private readonly _labels: GUI.TextBlock[] = []
  private readonly _ui = createGui('axis-ui')
  private _visibility = false

  constructor(private _size: number, private _scene: B.Scene) {
  }

  rebuildCoords(minVec: B.Vector3, maxVec: B.Vector3, shift: B.Vector3 = B.Vector3.Zero()) {
    for (const m of this._meshes) m.dispose()
    for (const m of this._labels) m.dispose()
    this._meshes.length = 0
    this._buildCoords(minVec, maxVec, shift)
    this.setVisibility(this._visibility)
  }

  setVisibility(isVisible: boolean) {
    this._visibility = isVisible
    for (const m of this._meshes) m.isVisible = isVisible
    for (const m of this._labels) m.isVisible = isVisible
  }

  private _buildCoords(minVec: B.Vector3, maxVec: B.Vector3, shift: B.Vector3) {
    // positive axis
    this._buildStandardAxis('x_max', maxVec.x, 'x', shift)
    this._buildStandardAxis('x_min', minVec.x, 'x', shift)
    this._buildStandardAxis('y_max', maxVec.z, 'z', shift)
    // this._buildStandardAxis('y_min', minVec.z, 'z', shift)
    this._buildStandardAxis('z_max', maxVec.y, 'y', shift)
    this._buildStandardAxis('z_min', minVec.y, 'y', shift)
  }

  private _buildStandardAxis(label: string, magnitude: number, direction: 'x' | 'y' | 'z', shift: B.Vector3) {
    const end = B.Vector3.Zero()
    end[direction] = magnitude

    const arrBack = this._size / 2, arrOut = this._size / 4
    let headShifts = [
      new B.Vector3(-arrBack, arrOut, 0),
      new B.Vector3(-arrBack, -arrOut, 0),
    ]
    switch (direction) {
      case "y":
        headShifts = [
          new B.Vector3(-arrOut, -arrBack, 0),
          new B.Vector3(arrOut, -arrBack, 0),
        ]
        break;
      case "z":
        headShifts = [
          new B.Vector3(0, -arrOut, -arrBack),
          new B.Vector3(0, arrOut, -arrBack),
        ]
        break;
    }

    const sign = Math.sign(magnitude)
    headShifts[0] = headShifts[0].multiplyByFloats(sign, sign, sign)
    headShifts[1] = headShifts[1].multiplyByFloats(sign, sign, sign)

    this._createAxis(`${label}=${magnitude.toFixed(2)}m`, shift, end.add(shift), headShifts)
  }

  private _createLinkedLabel(name: string, color: string, mesh: B.Mesh, offsetX: number, offsetY: number) {
    const label = new GUI.TextBlock();
    label.text = name;
    label.color = color
    this._ui.addControl(label);

    label.linkWithMesh(mesh);
    label.linkOffsetX = offsetX;
    label.linkOffsetY = offsetY;
    this._labels.push(label)
  }

  private _createAxis(name: string, start: B.Vector3, end: B.Vector3, headShifts: B.Vector3[], color="#FFFFFF") {
    const points = [start, end]
    const axis = B.MeshBuilder.CreateLines("axis" + name, {points}, this._scene)
    axis.color = B.Color3.FromHexString(color)
    this._meshes.push(axis)

    const headPts = []
    for (let i = 0; i < headShifts.length; i++) {
      if (i !== 0) headPts.push(end)
      const shift = headShifts[i]
      headPts.push(end.add(shift))
    }
    const head = B.MeshBuilder.CreateLines("axis" + name, {points: headPts}, this._scene)
    head.color = B.Color3.FromHexString(color)
    this._meshes.push(head)

    this._createLinkedLabel(name, color, head, 20, -10)

    const fullDir = end.subtract(start)
    const unitDir = fullDir.clone().normalize()
    const currentLoc = unitDir.clone()
    const hatchVec = new B.Vector3(unitDir.y, unitDir.z, unitDir.x).multiply(B.Vector3.Zero().setAll(-this._size))
    while (currentLoc.length() < fullDir.length()) {
      const hatch = B.MeshBuilder.CreateLines("hatch" + currentLoc.length(), {
        points: [
          currentLoc.add(start),
          currentLoc.add(start).addInPlace(hatchVec),
        ]
      }, this._scene)
      hatch.color = B.Color3.FromHexString(color)
      this._meshes.push(hatch)

      this._createLinkedLabel(currentLoc.length().toFixed(0)+"m", color, hatch, -20, 10)

      currentLoc.addInPlace(unitDir)
    }
  }
}