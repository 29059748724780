import Cloud from "./elements/Cloud";
import Arrows from "./elements/Arrows";
import CloudRenderAble from "./elements/CloudRenderAble";
import Centroids from "./elements/Centroids";
import Vitals from "./elements/Vitals";
import {CreateLabelGroup, CachedLabelGroup} from "./cachedLabelsGroup";

const MAX_CLOUD_POINT = 300
const CLOUD_POINT_DIAMETER = 0.03

class Clouds {
  private readonly _data: CloudRenderAble[] = []
  private readonly newCloudCallback: () => CloudRenderAble
  private _labelGroup: CachedLabelGroup | null = null

  constructor(newCloudCallback: () => CloudRenderAble) {
    this.newCloudCallback = newCloudCallback
  }

  public setup(count: number) {
    if (count < 1) count = 1
    if (count < this._data.length) this.downSize(count)
    else if (count > this._data.length) this.upSize(count)
  }

  public updateNextFrame(points: any[], isShow: boolean = true, isShowLabels = false) {
    if (this._data.length === 0) return
    const currentCloud = this._data.pop() as CloudRenderAble
    currentCloud.updateAll(points)
    isShowLabels && this.updateLabels(currentCloud, points)
    this._data.unshift(currentCloud)

    for (let i = 0; i < this._data.length; i++) {
      this._data[i].setVisibility(isShow ? 1 - i / this._data.length : 0)
    }
  }

  private upSize(size: number) {
    const nCloudToAdd = size - this._data.length
    for (let i = 0; i < nCloudToAdd; i++) {
      this._data.push(this.newCloudCallback())
    }
  }

  private downSize(size: number) {
    for (let i = size; i < this._data.length; i++) this._data[i].dispose()
    this._data.length = size
  }

  private updateLabels(cloud: CloudRenderAble, points: any[]) {
    let added: number[] = [];
    if (this._labelGroup === null) this._labelGroup = CreateLabelGroup("CloudUI")
    for (let i = 0; i < points.length; i++) {
      this._labelGroup.addLabelToMesh(i, cloud.getMesh(i), cloud.formatLabel(points[i]))
      added.push(i)
    }
    this._labelGroup.purge(added)
  }
}

export const pointClouds = new Clouds(() => new Cloud(MAX_CLOUD_POINT, CLOUD_POINT_DIAMETER))
export const arrowClouds = new Clouds(() => new Arrows(MAX_CLOUD_POINT, CLOUD_POINT_DIAMETER))
export const centroidClouds = new Clouds(() => new Centroids(5, CLOUD_POINT_DIAMETER * 2))
export const vitalClouds = new Clouds(() => new Vitals(2, CLOUD_POINT_DIAMETER * 1.5))
