import {Item, ItemType} from "../entities/Item";
import {ItemRepository} from "../entities/ItemRepository";
import * as B from "babylonjs"
import {Pose, Vec} from "../entities/Types";

export type ItemDTO = {
  id: string;
  name: string;
  type: ItemType;
  shape: number[],
  pos: Vec,
  rot: Vec,
  meta?: any,
}

// TODO get data from a browser storage such as localstorage/indexedDB
let itemData: ItemDTO[] = [
  {id: "1", name: "wall1", type: ItemType.Wall, shape: [4, 3], pos: [0, 0.5, 0], rot: [0, Math.PI, 0]},
  {id: "2", name: "wall2", type: ItemType.Wall, shape: [6, 3], pos: [-2, 0.5, 3], rot: [0, -Math.PI / 2, 0]},
  {id: "3", name: "wall3", type: ItemType.Wall, shape: [4, 3], pos: [0, 0.5, 6], rot: [0, 0, 0]},
  {id: "4", name: "wall4", type: ItemType.Wall, shape: [6, 3], pos: [2, 0.5, 3], rot: [0, Math.PI / 2, 0]},
  // {
  //   id: "6",
  //   name: "person1",
  //   type: ItemType.Person,
  //   shape: [0.3],
  //   pos: [0, 0, 1],
  //   rot: [0, 0, 0],
  //   meta: {velocity: [0, 0, 0]}
  // },
  {id: "7", name: "ground", type: ItemType.Ground, shape: [4, 6, 3], pos: [0, -1, 3], rot: [0, 0, 0]},
  {id: "8", name: "ceiling", type: ItemType.Ground, shape: [4, 6, 3], pos: [0, 2, 3], rot: [0, 0, Math.PI]},
  {id: "9", name: "bed", type: ItemType.Object, shape: [2, 1, 0.5], pos: [0, -0.75, 0.6], rot: [0, 0, 0]},
  {id: "10", name: "bedRegion", type: ItemType.Region, shape: [2, 1, 0.5], pos: [0, -0.75, 0.6], rot: [0, 0, 0]},
  {id: "11", name: "win1", type: ItemType.Window, shape: [1, 1.5], pos: [-2 + 0.01, .6, 4], rot: [0, -Math.PI / 2, 0]},
  {id: "12", name: "groundBody", type: ItemType.Object, shape: [4, 6, .1], pos: [0, -1.1, 3], rot: [0, 0, 0]},
]
let nextId = +itemData[itemData.length - 1].id + 1

export const updateResident = (updates: ItemDTO[]) => {
  for (const u of updates) {
    const datum = itemData.find(d => d.name === u.name)
    if (datum) {
      if (u.type) datum.type = u.type
      if (u.shape) datum.shape = u.shape
      if (u.pos) datum.pos = u.pos
      if (u.rot) datum.rot = u.rot
      if (u.meta) datum.meta = u.meta
    } else {
      itemData.push({...u, id: "" + nextId})
      nextId++
    }
  }
}

export const removeResidentItemPrefix = (prefix: string) => {
  const removedItems = itemData.filter(item => item.name.startsWith(prefix))
  for (const removedItem of removedItems) {
    if (removedItem.name in ItemCache) ItemCache[removedItem.name].shape.dispose()
  }
  itemData = itemData.filter(item => !item.name.startsWith(prefix))
}

const ItemCache: Record<string, Item> = {}

export class ItemRepositoryImpl implements ItemRepository {
  async AddItem(name: string, type: ItemType, shape: number[], pos: Vec, rot: Vec, meta: any): Promise<Item> {
    const dto: ItemDTO = {
      id: "" + nextId, name, type, shape, pos, rot, meta
    }
    nextId++
    return toItem(dto)
  }

  async BuildItems(): Promise<Item[]> {
    return itemData.map((i: ItemDTO) => {
      const newItem = toItem(i)
      if (i.name in ItemCache) ItemCache[i.name].shape.dispose()
      ItemCache[i.name] = newItem
      return newItem
    });
  }
}

const lastItemShapes:Record<string, B.Mesh> = {}

function toItem(i: ItemDTO): Item {
  let shape: B.Mesh
  switch (i.type) {
    case ItemType.Window:
    case ItemType.Wall:
      shape = makeWall(i.name, i.shape as any)
      shape.receiveShadows = true
      break
    case ItemType.Person:
      shape = makePerson(i.name, i.shape as any)
      if (i.meta.visibility !== undefined) shape.visibility = i.meta.visiblity
      break
    case ItemType.Ground:
      shape = makeGround(i.name, i.shape as any)
      shape.receiveShadows = true
      break
    case ItemType.Object:
    case ItemType.Device:
    default:
      shape = makeDevice(i.name, i.shape as any)
      break
  }
  lastItemShapes[i.id]?.dispose()
  delete lastItemShapes[i.id]
  lastItemShapes[i.id] = shape

  const pose = new Pose()
  if (shape) {
    shape.position = B.Vector3.FromArray(i.pos)
    shape.rotation = B.Vector3.FromArray(i.rot)
    const rotM = B.Matrix.RotationYawPitchRoll(i.rot[1], i.rot[0], i.rot[2]);
    const f = B.Vector3.TransformCoordinates(B.Vector3.Forward(), rotM);
    pose.position = i.pos
    pose.forward = [f.x, f.y, f.z]
    pose.shape = i.shape
  }
  return new Item(i.id, i.name, i.type, pose, shape, i?.meta)
}

function makeWall(name: string, [width, height]: [number, number]) {
  return B.MeshBuilder.CreatePlane(name, {width, height})
}

function makeDevice(name: string, [width, depth, height]: [number, number, number]) {
  const faceUV = [
    new B.Vector4(0, 0, 0.5, 1),
    new B.Vector4(0.5, 0, 1, 1),
    B.Vector4.Zero(),
    B.Vector4.Zero(),
    B.Vector4.Zero(),
    B.Vector4.Zero(),
  ]

  const options = {faceUV: faceUV, wrap: true, width, depth, height};
  return B.MeshBuilder.CreateBox(name, options)
}

function makePerson(name: string, [diameter]: [number]) {
  return B.MeshBuilder.CreateSphere(name, {diameter})
}

function makeGround(name: string, [width, height, subdivisions]: [number, number, number]) {
  return B.MeshBuilder.CreateGround(name, {width, height, subdivisions})
}
